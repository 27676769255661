
import { defineComponent } from "vue";
import { productParams } from "@/graphql/utils/product";

export default defineComponent({
  name: "ProductName",
  props: ["product"],
  setup() {
    return {
      productParams,
    };
  },
});
