
import { defineComponent } from "vue";
import { categoryParams } from "@/graphql/utils/product";

export default defineComponent({
  name: "CategoryName",
  props: ["category"],
  setup() {
    return {
      categoryParams,
    };
  },
});
