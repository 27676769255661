export default {
  title: "Réinitialisation",
  success: "Mot de passe réinitialisé",
  codeWrong: "Code entrée invalide",
  formTitle: "Réinitialisation du mot de passe",
  promptLabel: "Entrer le mot de passe",
  confirm: "Confirmation du mot de passe",
  enterCode: "Code de validation",
  submit: "Valider",
  tentative: "Renvoi du code",
  resent: "Renvoyer le code",
  userNotFound: "L'utilisateur n'existe pas.",
};
