export default {
  status: [
    "Payé", //directly payed
    "Crédit",
    "En attente",
    "Payé", // payed by credits
    "Annulé",
    "Retour",
    "Offert",
  ],
  status0: "Status",
  cancelledBy: "Ce ticket a été annulé par le ticket de retour n°{number}.",
  returnedBy: "Ce ticket de retour annule la vente n°{number}.",
  creditList: "Liste des tickets à crédit",
  waitingList: "Liste des tickets en attente",
  salesList: "Liste des tickets de vente",
  wait: "Mettre en attente",
  productCount: "Nombre produit : {count}",
  number: "n°{number}",
  number_: "N°Ticket",
  warningRemove:
    "Les produits du ticket seront retournés en stock et la recette sera supprimée. Etes-vous sûr de supprimer ?",
  unpayed: "Impayé",
  payed: "Payé",
  sale: "Vente",
  actual: "Actuel",
  revenue: "Revenue",
  expenses: "Dépenses",
  income: "Recettes",
  box: "Tresorerie",
  balance: "Solde",
  discountAmount: "Valeur de la remise",
  discountPercentage: "Remise en poucentage",
  totalValidate: "Montant des ventes(requête en cours)",
  totalTooltip: "Paiements comptants + crédits",
  details: "Détails du ticket",
  failed: "Une erreur est survenue. Réessayer plus tard!",
  create: {
    title0: "Ticket de vente",
    success0: "Ticket de vente crée avec succès",
    title1: "Ticket à crédit",
    success1: "Mise à crédit avec succès",
    title2: "Ticket en attente",
    success2: "Mise en attente avec succès",
  },
  update: {
    title0: "Ticket de vente",
    success0: "Mise à jour du ticket avec succès",
    title1: "Ticket à crédit",
    success1: "Mise à crédit avec succès",
    title2: "Ticket en attente",
    success2: "Mise en attente avec succès",
    title3: "Paiement de crédit",
    success3: "L'intégralité de crédit est payée",
    warning:
      "Etes-vous sûr de bien vouloir modifier ce ticket ? La vente sera annulée.",
  },
  remove: {
    title4: "Retour de vente",
    success4: "Vente annulée avec succès",
    title5: "Suppression de ticket",
    success5: "Ticket supprimé avec succès",
  },
  recover: {
    title: "Récupération de ticket",
    failed: "Echec de récupération du ticket",
  },
  salesAmount: "Montant de ventes",
  creditPayments: "Paiement crédit",
  sales: "Vente brute",
};
