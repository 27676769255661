import { activeActivity, ActiveActivity, i18n } from "@/plugins/i18n";
import { CONSTANTS, numberSeparators } from "@/graphql/utils/utils";

export const setNumberFormat = () => {
  i18n.global.mergeNumberFormat(
    numberSeparators[activeActivity.value.separator],
    activeActivity.value.numberFormats as any
  );
  localStorage.setItem(
    CONSTANTS.activeActivity,
    JSON.stringify(activeActivity.value)
  );
};

export const setActiveActivity = (activity: Partial<ActiveActivity>) => {
  activeActivity.value.numberFormats.currency.currency =
    activity.currencySymbol;
  activeActivity.value.numberFormats.decimal.maximumFractionDigits =
    activity.decimalNumber;
  activeActivity.value.id = activity.id;
  Object.assign(activeActivity.value, activity);
  setNumberFormat();
  sessionStorage.removeItem("STOCK_ACTIVE_INDEX");
};

export const logout = (href = true) => {
  localStorage.removeItem(CONSTANTS.activeActivity);
  localStorage.removeItem(CONSTANTS.activeTerminal);
  localStorage.removeItem(CONSTANTS.token);
  if (href) {
    const appSrc = localStorage.getItem(CONSTANTS.appSource);
    window.location.href = appSrc && appSrc.trim() != "null" ? appSrc : "/";
  }
};

export const disconnectActivity = () => {
  Object.assign(activeActivity.value, new ActiveActivity());
  localStorage.setItem(
    CONSTANTS.activeActivity,
    JSON.stringify(activeActivity.value)
  );
};
