export default {
  title: "Gérer les stocks",
  product: "Produit",
  quantity: "Quantité",
  price: "Prix",
  threshold: "Seuil",
  expiredAt: "Expiration",
  alertAt: "Date d'alerte",
  details: "Détails du stock",
  addition: "Entrée de stock",
  subtraction: "Sortie de stock",
  searchProduct: "Nom du produit et taper sur la touche entrée",
  in: "Les mouvements relatifs au stock",
  remove: "Supprimer ce stock",
  removeWarning: "Etes-vous sûr de vouloir supprimer ce stock ?",
  stock: "Stock",
  last: "Dernière entrée, le",
  exist: "Un stock équivalent existe déjà",
  variation: "Variation de la quantité",
  addQuantity: "Saisir la quantité",
  no: "Aucun stock !",
  available: "Les stocks disponibles",
  choose: "Stock à déduire",
  haveNoStock: "Actuellement, ce produit n'a plus aucun stock !",
  movement: "Mouvement",
  purchasePrice: "Prix de revient",
  sellingPrice: "Prix de vente TTC",
  list: "Les stocks relatifs au produit : {name}",
  failed: "Une erreur est survenue. Réessayer plus tard",
  newEntry: {
    title: "Nouveau stock",
    success: "Stock entré avec succès",
    failed:
      "Une ligne de stock identique existe déjà. Veuillez simplement ajuster la ligne de stock existante.",
  },
  newExit: {
    title: "Nouvelle sortie de stock",
    submit: "Valider la sortie",
    subtitle: "Nouvelle sortie",
    success: "Stock sorti avec succès",
  },
  delete: {
    title: "Suppression de stock",
    used: "Impossible de supprimer. Ce stock présente des lignes de ventes.",
    success: "Stock supprimé avec succès",
  },
  update: {
    title: "Mise à jour de stock",
    success: "Mis à jour effectué avec succès",
    failed: "Une erreur est survenue. Réessayer plus tard",
    save: "Enregistrer les modifications",
  },
  reasons: [
    "Ajout",
    "Retour de vente",
    "Annulation de vente",
    "Vente",
    "Autres",
  ],
  quantitySub: "Quantité à soustraire",
  stockNumber: "Nombre stock : {n}",
  empty: "Vide",
  inventory: "Inventaire",
  byProduct: "Par produit",
  documents: "Documents",
  detail: "Détails",
  exp: "Exp.",
  dateError: "Date d'alerte doit être antérieure à la date d'expiration",
  expiration: "Alertes de stock",
  status: "Statut",
  leftDays: "Dans {days} jours",
  tomorrow: "Expiré demain",
  expired: "Expiré",
  rupture: "En rupture",
  stockRupture: "{count} stock en dessous du seuil minimum",
  stocksRupture: "{count} stocks en dessous du seuil minimum",
  stockExpired: "{count} stock expiré",
  stocksExpired: "{count} stocks expirées",
  stockWill: "{count} stock va bientôt s'expirer",
  stocksWill: "{count} stocks vont bientôt s'expirer",
  noteError: "La note ne doit pas depasser 255 caractères.",
  initial: "Stock de départ",
  final: "Stock final",
  modification: "Modification",
  gap: "Ecart stock/seuil",
  stockInExpiration: "Stock en cours d'expiration",
  stockInThreshold: "Stocks en dessous du seuil minimum",
  entry: "Entrée de stock",
  out: "Sortie de stock",
  actual: "Stock actuel",
  inTitle: "Addition des stocks",
  inDetails: "Les quantités saisies seront rajoutées aux stocks correspondants",
  outTitle: "Soustraction des stocks",
  outDetails:
    "Les quantités saisies seront soustraites aux stocks correspondants",
};
