export default {
  masked: "Catégorie masquée",
  listUnMasked: "Catégories non masquées",
  listMasked: "Catégories masquées",
  principal: "Catégorie principale",
  parent: "Catégorie parente",
  name: "Nom",
  search: "Chercher par nom",
  create: {
    title: "Créer une catégorie",
    success: "Catégorie créée avec succès",
  },
  update: {
    title: "Mise à jour de la catégorie",
    success: "Catégorie modifiée avec succès",
  },
  warning:
    "Si vous supprimer cette catégorie, toutes ses catégories enfants et ses produits ne seront plus visibles dans le POS jusqu’à ce que vous leur attribuiez une nouvelle catégorie.",
  uncategorized: "Non catégorisé",
  exist: "Une catégorie portant le même nom existe déjà.",
  categories: "Categories",
  label: "Libéllé",
  none: "Aucune catégorie disponible",
  expenseWarning: "Etes-vous sûr de vouloir supprimer cette catégorie ?",
  hasExpense:
    "Impossible de supprimer! Il existe des dépenses effectuées au niveau de cette catégorie.",
};
