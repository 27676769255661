export default {
  formLabel: {
    add: "Ajouter un client",
    delete: "Supprimer un client",
    modify: "Modifier le client",
  },
  name: "Nom",
  phone: "Téléphone",
  email: "Adresse email",
  exists: "Vous ne pouvez pas utiliser {contact}. Ce contact existe déjà.",
  address: "Adresse",
  activityName: "activité",
  createdAt: "Date de création",
  dataTableLoading: "Chargement ..., patienter s'il vous plaît.",
  noData: "Aucun résultat trouvé.",
  error: {
    createCustomer: "Ajout client.",
    updateCustomer: "Modification échouée.",
    delete: "Suppression échouée.",
  },
  create: {
    title: "Nouveau client",
    success: "Client enregistré avec succès!",
    failed: "Echec de création ! Erreur interne du serveur!",
    exist: "Ce client fait déjà parti de l'activité.",
  },
  update: {
    title: "Mise à jour de client",
    success: "Client modifié avec succès!",
    failed: "Echec de mise à jour ! Erreur interne du serveur!",
  },
  delete: {
    title: "Suppression du client",
    success: "Client supprimé avec succès!",
    failed: "Echec de mise à jour ! Erreur interne du serveur!",
    used: "Impossible de supprimer ce client! Au moins une vente figure en son nom.",
  },
  customerName: "Nom du client",
  findClient: "Recherche de clients",
  emptySuggestion: "Aucune de suggestion.",
  suggestionInfo: "Clicker parmi les suggestions pour selectionner.",
  cancelSeleted: "Annuler la selection",
  filterByName: "Chercher par nom",
  filterByPhone: "Chercher par numero de téléphone",
  filterByEmail: "Chercher par adresse email",
  filterByAddress: "Chercher par adresse",
  filterByCreateAt: "Chercher par date de création",
  customers: "Clients",
  active: "Actif",
  registered: "Enregistrés",
  default: "Au comptoir",
};
