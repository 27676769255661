import { i18n } from "@/plugins/i18n";

export const globalGuard = (to) => {
  document.title = `${process.env.VUE_APP_NAME} - ${i18n.global.t(
    "workspaceLayout." + String(to.name)
  )}`;
  /*if (to.name == "home" && localStorage.getItem(CONSTANTS.token)) {
    next("/workspace");
  } else next();*/
};
