import { reactive } from "vue";

export const stockExpNotifier = reactive({
  expired: 0,
  threshold: 0,
});

export const AppSource = {
  web: {
    name: "web",
    account: "/account",
    home: "/",
  },
  ios: {
    name: "ios",
    account: "/account/ios",
  },
  android: {
    name: "android",
    account: "/account/android",
  },
  desktop: {
    name: "desktop",
    account: "/account/desktop",
  },
};
