export default {
  subject: "Sujet",
  message: "Entrez votre message ici ...",
  drop: "Ecrivez-nous quelques lignes",
  touch: "Prise de contact",
  success: "Votre message a été envoyé",
  contacts: ["Assistance", "Adresse email", "Disponibilité"],
  contact: "Contact",
  subscription: "Abonnements",
  subscription0: "Abonnement",
  ourSub: "Nos abonnements",
  choose: "Choisissez le forfait qui convient le mieux à vos besoins",
  choose0: "Payez au mois ou à l’année ou essayez gratuitement.",
  choose1:
    "Dans tous les cas, vous aurez accès à toutes les fonctionnalités de la platforme.",
  feature: "Fonctionnalités",
  title: "Gérer votre stock, vos crédits clients, vos dépenses",
  title1: "Générer un rapport afin de faire les comptes",
  discover: "Découvrez notre solution",
  discover1: "Abonnements & Tarifs",
  ourFeature: "Nos fonctionnalités",
  ourPrice: "Nos tarifs",
  contactUs: "Nous contacter",
  name: "Votre nom",
  email: "Votre adresse email",
  subscriptions: ["Gratuit", "Mensuel", "Trimestriel", "Semestriel", "Annuel"],
  types: ["Utilisation gratuite", "Abonnement mensuel", "Abonnement annuel"],
  duration0: "{duration} mois d'essai",
  duration1: "{duration} mois d'abonnement",
  pageName: "Accueil",
};
