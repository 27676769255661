export default {
  title: "Gérer l'accès à {activity}",
  noResponsible: "Vous n'avez pas de collaborateur pour cette activité.",
  invite: "Inviter un utilisateur",
  search: "Chercher",
  placeholder: "Chercher un reponsable",
  pending: "Invitation en attente",
  email: "Entrez l’adresse email de l’invité",
  invitation: "Invitation à rejoindre {activity}",
  notResponsible: "Cet email ne figure à aucun compte",
  role: "Séléctionner le rôle",
  gone: "{name} a quitté l'activité",
  create: {
    title: "Invitation",
    success: "Invitation envoyée !",
    failed: "Cette utilisateur a déjà été invité à rejoindre l'activité.",
  },
  confirmation: {
    success: "Invitation acceptée",
    failed: "Echec d'opération. Réessayer plutard",
  },
  remove: {
    title: "Suppression",
    success: "Suppression avec succès",
    confirm: "Êtes-vous sur de vouloir supprimer?",
  },
  leave: {
    title: "Quitter",
    confirm: "Etes-vous sûr de vouloir quitter cette activité ?",
    success: "Vous n'êtes plus membre de l'activité.",
    failed:
      "Vous ne pouvez pas quitter l'actvité pour l'instant. Réessayez plus tard.",
  },
  failed: "Une erreur est survenue. Réessayez plus tard!",
  collaborators: "Collaborateurs",
  newRole: "Editer le rôle de {name}",
  exist:
    "Impossible d'inviter cette adresse email. La personne est déjà membre de votre activité ou a été déjà invitée .",
};
