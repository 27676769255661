export default {
  general: "Informations générales",
  name: "Nom",
  category: "Catégorie",
  categories: "Catégories",
  type: "Type",
  label: "Libellé",
  reference: "Réf/Code barre",
  vat: "TVA",
  exTax: "Prix HT",
  inTax: "Prix TTC",
  exTax0: "Prix de vente HT",
  inTax0: "Prix de vente TTC",
  article: "Article",
  purchaseCost: "Prix de revient unitaire",
  purchasePrice: "Prix de revient",
  quantity: "Quantité",
  expiredAt: "Date d'expiration ",
  alertAt: "Date d'alerte avant l'expiration",
  threshold: "Seuil de stock",
  sellingPrice: "Prix de vente",
  refExist: "La référence du produit existe déjà !",
  filterByRef: "Chercher par référence",
  filterByName: "Chercher par nom",
  new: "Nouveau produit",
  all: "Tous produits",
  product: "Produit",
  service: "Non stockable",
  storable: "Stockable",
  notStorable: "Non stockable",
  newLine: "Nouvelle ligne",
  action: "Action",
  delete: {
    title: "Suppression du produit",
    success: "Prooduit supprimé avec succès",
    failed: "Une erreur est suvenue. Réessayer !",
    unable:
      "Impossible de supprimer ce produit car il a déjà été utilisé dans l’application. Vous pouvez le déplacer dans la catégorie masquée si vous ne souhaitez plus le voir dans votre caisse.",
  },
  create: {
    title: "Création de produit",
    success: "Produit crée avec succès",
    failed: "Echec de création du produit! Une erreur est suvenue.",
    storable: "Informations de stock",
    notStorable: "Produit sans gestion de stock",
    service: "Il s'agit d'un service",
    noValidatedField: "Aucun champs valide",
    totalCreated: "Total de produits crées",
  },
  types: ["Avec stock", "Sans stock"],
  importCsv: "Importer CSV",
  chooseCsv: "Choisir un fichier CSV",
  downloadModel: "Télécharger le modèle",
  csvStatistics: "Importation : {import}, Auto-correction : {correction}",
  categoryNotFound: "Catégorie n'existe pas dans la base de données.",
  refMissing: "Référence manquante",
  nameMissing: "Nom du produit manquant",
  changeable: "Prix modifiable dans le POS",
  codeBar: "Recherche par code barre",
  codeBarPlaceholder: "Scannez votre code barre ici",
  codeBarSummary: "Produit introuvable",
  codeBarDetail: "Code barre {code}",
  ref: "Ref",
  barcodePrinter: "Générateur d'étiquette",
  barcodeAlreadyThere: "Code barre déjà généré !",
  totalSellingPrice: "Total P. Vente",
  totalPurchasePrice: "Total P. Revient",
};
