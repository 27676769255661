import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  allowfullscreen: "",
  id: "print-frame"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("iframe", _hoisted_1, null, 512), [
      [_vShow, false]
    ]),
    _createVNode(_component_router_view),
    _createVNode(_component_Toast, { breakpoints: { '920px': { width: '95%', right: '0', left: '0' } } }),
    _createVNode(_component_ConfirmPopup, { style: {"max-width":"300px"} }),
    _createVNode(_component_ConfirmDialog, {
      style: {"max-width":"400px"},
      group: "dialog",
      class: "p-mx-2"
    })
  ], 64))
}