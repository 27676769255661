export default {
  activity: "Activités",
  admin_activity: "Activités",
  product: "Produits",
  customer: "Clients",
  dashboard: "Tableau de board",
  admin_dashboard: "Tableau de board",
  category: "Categories",
  rapport: "Rapports",
  stock: "Stocks",
  expense: "Dépenses",
  user: "Utilisateurs",
  workspace: "Espace de travail",
  documentation: "Documentation",
  administration: "Administration",
  "sub-payment": "Historique de payments",
  subscription: "Abonnements",
  pos: "POS",
  pos2: "Point de ventes",
  home: "Accueil",
  signIn: "Connexion",
  signUp: "Inscription",
  resetPassword: "Réinitialisation de mot de passe",
  verifyAccount: "Vérification du compte",
  forgotPassword: "Mot de passe oublié",
  account: "Mon compte",
  menuType: "Type de menu",
  static: "Statique",
  overlay: "Superposition",
  slim: "Mince",
  horizontal: "Horizontal",
  colorScheme: "Schéma de couleur",
  dim: "Sombre",
  light: "Clair",
  inputStyle: "Style des entrées",
  outlined: "Décrite",
  filled: "Remplie",
  rippleEffect: "Effet d'entraînement",
  menuTheme: "Thème de menu",
  componentTheme: "Thème des composants",
  menuThemeException: `Les thèmes de menu ne sont disponibles qu'en mode clair.`,
  blue: "Bleu",
  lightgreen: "Vert clair",
  white: "Blanc",
  darkgray: "Gris noir",
  on: "Activé",
  off: "Désactivé",
  settings: "Paramètres",
  infos: "Informations personnelles",
  lastConnexion: "Dernière connexion",
  security: "Sécurités",
  myAccount: "Mon compte",
  story: "Historique d'activité",
  newInvitation: "Invitation",
  count: `Vous avez {count} nouvelles invitations`,
  info: "Alerte",
  connect: "Veuillez d'abord vous connecter à une activité.",
  pay: "Vous devez payer votre abonnement.",
  unauthorized: "Vous ne disposez pas des droits d’accès à cette section.",
  docProduct: "Produits",
  docCustomer: "Clients",
  docDashboard: "Tableau de board",
  docActivity: "Activités",
  docCategory: "Categories",
  docRapport: "Rapports",
  docStock: "Stock",
  docExpense: "Dépenses",
  docAccount: "Compte d'utilisateur",
  docPos: "Point de vente",
  terminal: "Terminal",
  instigator: "Promoteurs",
  exception: "Page introuvable",
  privacy: "Politique de confidentialité",
  terminalsLoading: "Accès POS",
  waitTerminals: "Veuillez attendre le chargment des terminaux ...",
  waitLoadedSuccess: "Chargement terminé. Vous pouvez vous connecter au POS",
  waitLoadedFailed: "Chargement terminé. Aucun terminal trouvé !",
  hr: "RH",
};
