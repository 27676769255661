export default {
  welcome: "Connexion à l’espace personnel",
  email: "Email",
  password: "Mot de passe",
  forgot: "Mot de passe oublié ?",
  new: "Vous êtes nouveau ici ?",
  invite: "Inscrivez-vous et découvrez de nouvelles opportunités formidables!",
  signUp: "S'inscrire",
  connect: "Se connecter",
  connexion: "Connexion",
  unverified: "Veuiller verifier votre addresse email.",
  notFound: "Adresse email introuvable",
  wrongPassword: "Mot de passe erroné",
  username: "Nom de l'utilisateur",
  wrongCurrentPassword: "Mot de passe actuel erroné",
  validation: {
    email: "Entrer un email valide",
    password: "Mot de passe requis",
  },
  chooseActivity: "Quelle activité souhaitez-vous utiliser ?",
  stayConnected: "Rester connecté",
  accountStatus: "Statut du compte",
  inProgress: "En cours",
  validated: "Validé",
  activate: "Valider l'inscription",
};
