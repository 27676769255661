import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type Access = {
  __typename?: 'Access';
  create: Scalars['Boolean']['output'];
  delete: Scalars['Boolean']['output'];
  menu: EAccessMenu;
  read: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type AccessInput = {
  create?: InputMaybe<Scalars['Boolean']['input']>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  menu: EAccessMenu;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActiveSequenceOutput = {
  __typename?: 'ActiveSequenceOutput';
  sequence?: Maybe<Sequence>;
  tickets: Array<Ticket>;
};

export type Activity = {
  __typename?: 'Activity';
  address: Scalars['String']['output'];
  country: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  decimalNumber: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  ownerId: Scalars['Int']['output'];
  payments: Array<SubscriptionPayment>;
  revenue?: Maybe<Scalars['Float']['output']>;
  separator: Scalars['Float']['output'];
  slogan: Scalars['String']['output'];
  taxRate: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ActivityResetInput = {
  id: Scalars['Float']['input'];
  options: Array<Scalars['Int']['input']>;
};

export type AdjustExpirationInput = {
  activityId: Scalars['Int']['input'];
  expiredAt: Scalars['DateTime']['input'];
  paymentId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Category = {
  __typename?: 'Category';
  children: Array<Category>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  label: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['Int']['output'];
};

export type ChangeResponsibleRoleInput = {
  id: Scalars['String']['input'];
  role: Scalars['Int']['input'];
};

export type ComparativeRapportInput = {
  activityId: Scalars['Int']['input'];
  endAt: Scalars['DateTime']['input'];
  period?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['DateTime']['input'];
};

export type ComparativeRapportOutput = {
  __typename?: 'ComparativeRapportOutput';
  /** Payed amount */
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  /** Ticket total */
  total: Scalars['Float']['output'];
};

export type CreateActivityInput = {
  address: Scalars['String']['input'];
  country: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  currencySymbol: Scalars['String']['input'];
  decimalNumber: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  separator: Scalars['Int']['input'];
  slogan: Scalars['String']['input'];
  taxRate: Scalars['Float']['input'];
};

export type CreateCategoryInput = {
  activityId: Scalars['Int']['input'];
  label: Scalars['String']['input'];
  parentId: Scalars['Int']['input'];
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateEmployeeInput = {
  /** Contacts */
  contacts: Scalars['String']['input'];
  /** CV */
  cv?: InputMaybe<Scalars['Upload']['input']>;
  /** Date d'entrée de l'employée */
  departureDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Date d'entrée de l'employée */
  entryDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstName: Scalars['String']['input'];
  functionId: Scalars['Int']['input'];
  /** Pièce d'identité */
  identity?: InputMaybe<Scalars['Upload']['input']>;
  lastName: Scalars['String']['input'];
  sectorId: Scalars['Int']['input'];
};

export type CreateExpenseInput = {
  activityId: Scalars['Int']['input'];
  amount: Scalars['Float']['input'];
  categoryId: Scalars['Int']['input'];
  motif: Scalars['String']['input'];
  sequenceId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFunctionInput = {
  /** Example field (placeholder) */
  name: Scalars['String']['input'];
};

export type CreatePaymentReceiptInput = {
  change: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  method: Scalars['Int']['input'];
  note: Scalars['String']['input'];
  received: Scalars['Float']['input'];
  status?: InputMaybe<Scalars['Int']['input']>;
  ticketId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProductInput = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  categoryId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  notStorableInput: NotStorableInput;
  reference: Scalars['String']['input'];
  stockInput?: InputMaybe<StockInput>;
  threshold?: InputMaybe<Scalars['Float']['input']>;
  type: Scalars['Int']['input'];
};

export type CreateRoleInput = {
  name: Scalars['String']['input'];
};

export type CreateSectorInput = {
  /** name of sector */
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateStockInput = {
  activityId: Scalars['Int']['input'];
  alertAt?: InputMaybe<Scalars['String']['input']>;
  exclTax: Scalars['Float']['input'];
  expiredAt?: InputMaybe<Scalars['String']['input']>;
  inclTax: Scalars['Float']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Int']['input'];
  purchasePrice: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type CreateTerminalInput = {
  active: Scalars['Int']['input'];
  activityId: Scalars['Int']['input'];
  allowDiscountLines: Scalars['Int']['input'];
  categories: Array<TerminalCategoryInput>;
  codeBar: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  printBarcode: Scalars['Int']['input'];
  printStockPos: Scalars['Int']['input'];
  printerMargins: Array<Scalars['Int']['input']>;
  printerSettings: Array<Scalars['Int']['input']>;
  userIds: Array<Scalars['Int']['input']>;
};

export type CreateTicketInput = {
  activityId: Scalars['Int']['input'];
  amount: Scalars['Float']['input'];
  customerId?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<CreatePaymentReceiptInput>;
  percentage: Scalars['Float']['input'];
  sequenceId: Scalars['String']['input'];
  status: Scalars['Int']['input'];
  ticketLines: Array<TicketLineInput>;
};

export type CreateTicketOutput = {
  __typename?: 'CreateTicketOutput';
  stocks: Array<Stock>;
  ticket: Ticket;
};

export type CreateUserInput = {
  activation: Scalars['Float']['input'];
  email: Scalars['String']['input'];
  instigatorId?: InputMaybe<Scalars['Float']['input']>;
  language: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  settings: Array<Scalars['Float']['input']>;
};

export type CurrentRevenueBySubscription = {
  __typename?: 'CurrentRevenueBySubscription';
  activityCount: Scalars['Int']['output'];
  amountSum: Scalars['Float']['output'];
  subscriptionId: Scalars['Int']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type CustomerCreateInput = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPagination = {
  __typename?: 'CustomerPagination';
  items: Array<Customer>;
  meta: Meta;
};

export type CustomerUpdateInput = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomersDeleteOutput = {
  __typename?: 'CustomersDeleteOutput';
  id: Scalars['String']['output'];
  ticketsCount: Scalars['Int']['output'];
};

export type CustomersOverviewOutput = {
  __typename?: 'CustomersOverviewOutput';
  active: Scalars['Int']['output'];
  registered: Scalars['Int']['output'];
};

export type DeleteCustomerInput = {
  activityId: Scalars['Float']['input'];
  customerId: Scalars['String']['input'];
};

export type Document = {
  __typename?: 'Document';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  movements: Array<Movement>;
  number: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
};

export type DocumentPagination = {
  __typename?: 'DocumentPagination';
  items: Array<Document>;
  meta: Meta;
};

export enum EAccessMenu {
  Activity = 'activity',
  Category = 'category',
  Customer = 'customer',
  Dashboard = 'dashboard',
  Expense = 'expense',
  Pos = 'pos',
  Product = 'product',
  Rapport = 'rapport',
  Rh = 'rh',
  Stock = 'stock',
  Terminal = 'terminal'
}

export type EmailOptions = {
  cc?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type Employee = {
  __typename?: 'Employee';
  active: Scalars['Int']['output'];
  /** Contact */
  contacts: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Copie d'identité */
  cv: Scalars['String']['output'];
  /** Date d'entrée de l'employée */
  departureDate?: Maybe<Scalars['DateTime']['output']>;
  /** Date d'entrée de l'employée */
  entryDate?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  function?: Maybe<Fonction>;
  id: Scalars['Int']['output'];
  /** Copie d'identité */
  identity: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  sector?: Maybe<Sector>;
};

export type Expense = {
  __typename?: 'Expense';
  amount: Scalars['Float']['output'];
  category?: Maybe<Category>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  motif: Scalars['String']['output'];
  sequenceId?: Maybe<Scalars['String']['output']>;
};

export type Fonction = {
  __typename?: 'Fonction';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Instigator = {
  __typename?: 'Instigator';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  users: Scalars['Int']['output'];
};

export type InstigatorCreateInput = {
  name: Scalars['String']['input'];
};

export type InstigatorUpdateInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type InviteResponsibleInput = {
  activityId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: Scalars['Float']['input'];
};

export type Logger = {
  __typename?: 'Logger';
  id: Scalars['Int']['output'];
};

export type LoginDto = {
  __typename?: 'LoginDto';
  token: Scalars['String']['output'];
  user: User;
};

export type Meta = {
  __typename?: 'Meta';
  currentPage: Scalars['Float']['output'];
  itemCount: Scalars['Float']['output'];
  itemsPerPage: Scalars['Float']['output'];
  totalItems: Scalars['Float']['output'];
  totalPages: Scalars['Float']['output'];
};

export type MonthlyRevenueInput = {
  activityId: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type MonthlyRevenueOutput = {
  __typename?: 'MonthlyRevenueOutput';
  amount: Scalars['Float']['output'];
  month: Scalars['Float']['output'];
};

export type Movement = {
  __typename?: 'Movement';
  createdAt: Scalars['DateTime']['output'];
  documentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  motif: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  product: Product;
  qStock: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  stock?: Maybe<Stock>;
  stockId?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Ticket>;
  ticketId?: Maybe<Scalars['String']['output']>;
  vat: Scalars['Float']['output'];
};

export type MovementPagination = {
  __typename?: 'MovementPagination';
  items: Array<Movement>;
  meta: Meta;
};

export type MovementProductsRapportDto = {
  __typename?: 'MovementProductsRapportDto';
  amount: Scalars['Float']['output'];
  category: Scalars['String']['output'];
  categoryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  product: Scalars['String']['output'];
  productId: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  ticket: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation: Responsible;
  activityReset: Scalars['Boolean']['output'];
  activityRestore: Scalars['Boolean']['output'];
  adjustExpiration: Activity;
  changeResponsibleRole: Responsible;
  closeSequence: Sequence;
  contact: Scalars['Boolean']['output'];
  createActivity: Responsible;
  createCategory?: Maybe<Category>;
  createEmployee: Employee;
  createExpense: Expense;
  createFunction: Fonction;
  createLogger: Logger;
  createMultiProducts: Array<Product>;
  createProduct: Product;
  createRole: Role;
  createSector: Sector;
  createSequence: Sequence;
  createStock: Stock;
  createTerminal: Terminal;
  createTicket: CreateTicketOutput;
  createUser: User;
  customerCreate: Customer;
  customerUpdate: Customer;
  deleteActivity?: Maybe<Responsible>;
  deleteCustomer: CustomersDeleteOutput;
  deleteDefinitivelyResponsible?: Maybe<Scalars['Int']['output']>;
  deleteMovement: Stock;
  deleteProduct: Scalars['Float']['output'];
  deleteStock: Scalars['Int']['output'];
  instigatorCreate: Instigator;
  instigatorRemove: Scalars['Int']['output'];
  instigatorUpdate: Instigator;
  inviteResponsible?: Maybe<Responsible>;
  login: LoginDto;
  purchaseSubscription: SubscriptionPayment;
  removeCategory: RemoveCategoryOutput;
  removeDocument: RemoveDocumentOutput;
  removeEmployee: Scalars['Int']['output'];
  removeExpense: Scalars['Float']['output'];
  removeExpenseCategory: Scalars['Boolean']['output'];
  removeFunction: Scalars['Int']['output'];
  removeResponsible: Scalars['String']['output'];
  removeRole: Scalars['Int']['output'];
  removeSector: Scalars['Int']['output'];
  removeTicket: TicketRemoveOutput;
  resetPassword: User;
  sendCodePin?: Maybe<User>;
  sequenceCheckActiveTerminal?: Maybe<Sequence>;
  subscriptionUrl: Scalars['String']['output'];
  terminalConnect: Terminal;
  ticketRecover: Array<Stock>;
  updateActivity: Activity;
  updateByAdminUser: UserRevenue;
  updateCategory: Category;
  updateEmployee: Employee;
  updateExpense: Expense;
  updateFunction: Fonction;
  updateMovementQuantities: Array<Movement>;
  updateMovementQuantity: Movement;
  updatePassword?: Maybe<User>;
  updateProduct: Product;
  updateQuantities: Array<Stock>;
  updateRole: Role;
  updateSector: Sector;
  updateStock: Stock;
  updateSubscription: _Subscription;
  updateTerminal: Terminal;
  updateTicket: Ticket;
  updateUser: User;
  verifyEmail: User;
};


export type MutationAcceptInvitationArgs = {
  activityId: Scalars['Int']['input'];
};


export type MutationActivityResetArgs = {
  input: ActivityResetInput;
};


export type MutationActivityRestoreArgs = {
  activityId: Scalars['Int']['input'];
  file: Scalars['Upload']['input'];
};


export type MutationAdjustExpirationArgs = {
  input: AdjustExpirationInput;
};


export type MutationChangeResponsibleRoleArgs = {
  input: ChangeResponsibleRoleInput;
};


export type MutationCloseSequenceArgs = {
  id: Scalars['String']['input'];
};


export type MutationContactArgs = {
  input: EmailOptions;
};


export type MutationCreateActivityArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: CreateActivityInput;
};


export type MutationCreateCategoryArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: CreateCategoryInput;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationCreateExpenseArgs = {
  input: CreateExpenseInput;
};


export type MutationCreateFunctionArgs = {
  input: CreateFunctionInput;
};


export type MutationCreateLoggerArgs = {
  data: Scalars['String']['input'];
};


export type MutationCreateMultiProductsArgs = {
  input: Array<CreateProductInput>;
};


export type MutationCreateProductArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: CreateProductInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateSectorArgs = {
  input: CreateSectorInput;
};


export type MutationCreateSequenceArgs = {
  input: SequenceCreateInput;
};


export type MutationCreateStockArgs = {
  input: CreateStockInput;
};


export type MutationCreateTerminalArgs = {
  input: CreateTerminalInput;
};


export type MutationCreateTicketArgs = {
  input: CreateTicketInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCustomerCreateArgs = {
  input: CustomerCreateInput;
};


export type MutationCustomerUpdateArgs = {
  input: CustomerUpdateInput;
};


export type MutationDeleteActivityArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};


export type MutationDeleteDefinitivelyResponsibleArgs = {
  activityId: Scalars['Int']['input'];
};


export type MutationDeleteMovementArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteStockArgs = {
  id: Scalars['String']['input'];
};


export type MutationInstigatorCreateArgs = {
  input: InstigatorCreateInput;
};


export type MutationInstigatorRemoveArgs = {
  id: Scalars['Int']['input'];
};


export type MutationInstigatorUpdateArgs = {
  input: InstigatorUpdateInput;
};


export type MutationInviteResponsibleArgs = {
  input: InviteResponsibleInput;
};


export type MutationLoginArgs = {
  input: AuthInput;
};


export type MutationPurchaseSubscriptionArgs = {
  input: PurchaseSubscriptionInput;
};


export type MutationRemoveCategoryArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveDocumentArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveEmployeeArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveExpenseArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveExpenseCategoryArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveFunctionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveResponsibleArgs = {
  input: RemoveResponsibleInput;
};


export type MutationRemoveRoleArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveSectorArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveTicketArgs = {
  input: TicketRemoveInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSendCodePinArgs = {
  input: SendCodePinInput;
};


export type MutationSequenceCheckActiveTerminalArgs = {
  terminalId: Scalars['String']['input'];
};


export type MutationSubscriptionUrlArgs = {
  input: PurchaseSubscriptionInput;
};


export type MutationTerminalConnectArgs = {
  input: TerminalConnectedInput;
};


export type MutationTicketRecoverArgs = {
  input: TicketRecoverInput;
};


export type MutationUpdateActivityArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: UpdateActivityInput;
};


export type MutationUpdateByAdminUserArgs = {
  input: UserUpdateByAdminInput;
};


export type MutationUpdateCategoryArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: UpdateCategoryInput;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};


export type MutationUpdateExpenseArgs = {
  input: UpdateExpenseInput;
};


export type MutationUpdateFunctionArgs = {
  input: UpdateFunctionInput;
};


export type MutationUpdateMovementQuantitiesArgs = {
  input: Array<UpdateQuantityInput>;
};


export type MutationUpdateMovementQuantityArgs = {
  input: UpdateQuantityInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdateProductArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: UpdateProductInput;
};


export type MutationUpdateQuantitiesArgs = {
  input: UpdateQuantitiesInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateSectorArgs = {
  input: UpdateSectorInput;
};


export type MutationUpdateStockArgs = {
  input: UpdateStockInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationUpdateTerminalArgs = {
  input: UpdateTerminalInput;
};


export type MutationUpdateTicketArgs = {
  input: UpdateTicketInput;
};


export type MutationUpdateUserArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: UpdateUserInput;
};


export type MutationVerifyEmailArgs = {
  input: VerifyUserInput;
};

export type NotStorable = {
  __typename?: 'NotStorable';
  exclTax: Scalars['Float']['output'];
  inclTax: Scalars['Float']['output'];
  purchasePrice: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type NotStorableInput = {
  exclTax: Scalars['Float']['input'];
  inclTax: Scalars['Float']['input'];
  purchasePrice: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type PaginateCustomersInput = {
  activityId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type PaginateExpenseInput = {
  activityId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type PaginateMovementInput = {
  activityId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type PaginateProductInput = {
  activityId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  stock: Scalars['Boolean']['input'];
  withMasked: Scalars['Boolean']['input'];
};

export type PaginationInput = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type PaymentByType = {
  __typename?: 'PaymentByType';
  amount: Scalars['Float']['output'];
  type: Scalars['Int']['output'];
};

export type PaymentReceipt = {
  __typename?: 'PaymentReceipt';
  change: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  method: Scalars['Int']['output'];
  note: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  received: Scalars['Float']['output'];
  sequence?: Maybe<Sequence>;
  sequenceId: Scalars['String']['output'];
  ticket: Ticket;
};

export type PopularProductsOutput = {
  __typename?: 'PopularProductsOutput';
  count: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Product = {
  __typename?: 'Product';
  category?: Maybe<Category>;
  changeable: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notStorable?: Maybe<NotStorable>;
  reference: Scalars['String']['output'];
  stocks: Array<Stock>;
  threshold?: Maybe<Scalars['Float']['output']>;
  type: Scalars['Int']['output'];
};

export type ProductStock = {
  __typename?: 'ProductStock';
  category: Scalars['String']['output'];
  categoryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  product: Scalars['String']['output'];
  productId: Scalars['Int']['output'];
  stock: Scalars['Float']['output'];
};

export type ProductStockRapportPagination = {
  __typename?: 'ProductStockRapportPagination';
  items: Array<ProductStock>;
  meta: Meta;
};

export type PurchaseSubscriptionInput = {
  activityId: Scalars['Int']['input'];
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  designation: Scalars['String']['input'];
  expiredAt: Scalars['DateTime']['input'];
  subscriptionId: Scalars['Int']['input'];
  transactionId: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  activeSequence: ActiveSequenceOutput;
  activitiesCount: Scalars['Int']['output'];
  activityResponsible: Array<Responsible>;
  adminActivities: ResponsiblePagination;
  currentRevenueBySubscription: Array<CurrentRevenueBySubscription>;
  currentYearRevenueByMonth: Array<MonthlyRevenueOutput>;
  customersOverview: CustomersOverviewOutput;
  employees: Array<Employee>;
  expenseAmount: Scalars['Float']['output'];
  expenseCategories: Array<Category>;
  expenseCompareRapport: Array<ComparativeRapportOutput>;
  expensesRapport: Array<Expense>;
  functions: Array<Fonction>;
  getSoftRemovedResponsible: Array<Responsible>;
  income: Scalars['Float']['output'];
  instigators: Array<Instigator>;
  invitations: ResponsiblePagination;
  monthlyExpenses: Array<MonthlyRevenueOutput>;
  monthlyIncome: Array<MonthlyRevenueOutput>;
  movementProductsRapport: Array<MovementProductsRapportDto>;
  paginateCustomers: CustomerPagination;
  paginateDocuments: DocumentPagination;
  paginateExpenses: Array<Expense>;
  paginateStocks: StockPagination;
  paginateSubscriptionPayments: SubscriptionPaymentPagination;
  popularProducts: Array<PopularProductsOutput>;
  productCategories: Array<Category>;
  productDiscount: Product;
  productStockRapport: ProductStockRapportPagination;
  products: Array<Product>;
  recentSales: MovementPagination;
  roles: Array<Role>;
  salesRapport: Array<Ticket>;
  sectors: Array<Sector>;
  sequenceCategoriesRapport: Array<SequenceCategoriesRapport>;
  sequenceCreditsRapport: Array<SequenceCreditsRapport>;
  sequenceExpenseRapport: Scalars['Float']['output'];
  sequenceGet: Sequence;
  sequencePaymentsRapport: SequencePaymentsRapport;
  sequenceProductsRapport: Array<SequenceProductRapportDto>;
  sequenceTicketsBackRapport: Array<SequenceProductRapportDto>;
  sequencesPaginate: SequencePagination;
  sequencesRapport: Array<SequencesRapportOutput>;
  stockMovements: Array<StockMovementsOutput>;
  subscriptionPaymentTotalAmount: Scalars['Float']['output'];
  subscriptions: Array<_Subscription>;
  terminals: Array<Terminal>;
  ticketCompareRapport: Array<ComparativeRapportOutput>;
  ticketGet: Ticket;
  ticketMovements: Array<Movement>;
  ticketOverviews: Array<TicketOverviewsOutput>;
  ticketsOfActivity: Array<Ticket>;
  ticketsVolume: Array<TicketOverviewsOutput>;
  userPaymaster: Array<User>;
  userResponsible: Array<Responsible>;
  usersCount: Scalars['Int']['output'];
  usersRevenues: UsersRevenuePagination;
  whoAmI: User;
};


export type QueryActiveSequenceArgs = {
  terminalId: Scalars['String']['input'];
};


export type QueryActivityResponsibleArgs = {
  input: ResponsibleInput;
};


export type QueryAdminActivitiesArgs = {
  input: PaginationInput;
};


export type QueryCustomersOverviewArgs = {
  input: TicketsVolumeInput;
};


export type QueryEmployeesArgs = {
  activityId: Scalars['Int']['input'];
};


export type QueryExpenseAmountArgs = {
  input: TicketsVolumeInput;
};


export type QueryExpenseCategoriesArgs = {
  input: Scalars['Int']['input'];
};


export type QueryExpenseCompareRapportArgs = {
  input: ComparativeRapportInput;
};


export type QueryExpensesRapportArgs = {
  input: ComparativeRapportInput;
};


export type QueryFunctionsArgs = {
  activityId: Scalars['Int']['input'];
};


export type QueryGetSoftRemovedResponsibleArgs = {
  activityId: Scalars['Int']['input'];
};


export type QueryIncomeArgs = {
  input: TicketsVolumeInput;
};


export type QueryInvitationsArgs = {
  input: PaginationInput;
};


export type QueryMonthlyExpensesArgs = {
  input: MonthlyRevenueInput;
};


export type QueryMonthlyIncomeArgs = {
  input: MonthlyRevenueInput;
};


export type QueryMovementProductsRapportArgs = {
  input: SalesRapportInput;
};


export type QueryPaginateCustomersArgs = {
  input: PaginateCustomersInput;
};


export type QueryPaginateDocumentsArgs = {
  input: PaginateMovementInput;
};


export type QueryPaginateExpensesArgs = {
  input: ComparativeRapportInput;
};


export type QueryPaginateStocksArgs = {
  input: PaginateProductInput;
};


export type QueryPaginateSubscriptionPaymentsArgs = {
  input: SubscriptionPaymentPaginationInput;
};


export type QueryPopularProductsArgs = {
  input: TicketsVolumeInput;
};


export type QueryProductCategoriesArgs = {
  input: Scalars['Int']['input'];
};


export type QueryProductStockRapportArgs = {
  input: PaginateExpenseInput;
};


export type QueryProductsArgs = {
  activityId: Scalars['Int']['input'];
};


export type QueryRecentSalesArgs = {
  input: PaginateMovementInput;
};


export type QueryRolesArgs = {
  activityId: Scalars['Int']['input'];
};


export type QuerySalesRapportArgs = {
  input: SalesRapportInput;
};


export type QuerySectorsArgs = {
  activityId: Scalars['Int']['input'];
};


export type QuerySequenceCategoriesRapportArgs = {
  sequenceId: Scalars['String']['input'];
};


export type QuerySequenceExpenseRapportArgs = {
  sequenceId: Scalars['String']['input'];
};


export type QuerySequenceGetArgs = {
  sequenceId: Scalars['String']['input'];
};


export type QuerySequencePaymentsRapportArgs = {
  sequenceId: Scalars['String']['input'];
};


export type QuerySequenceProductsRapportArgs = {
  sequenceId: Scalars['String']['input'];
};


export type QuerySequenceTicketsBackRapportArgs = {
  sequenceId: Scalars['String']['input'];
};


export type QuerySequencesPaginateArgs = {
  input: SequencePaginateInput;
};


export type QuerySequencesRapportArgs = {
  input: ComparativeRapportInput;
};


export type QueryStockMovementsArgs = {
  input: ComparativeRapportInput;
};


export type QueryTerminalsArgs = {
  input: TerminalsInput;
};


export type QueryTicketCompareRapportArgs = {
  input: ComparativeRapportInput;
};


export type QueryTicketGetArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTicketMovementsArgs = {
  id: Scalars['String']['input'];
};


export type QueryTicketOverviewsArgs = {
  input: TicketsVolumeInput;
};


export type QueryTicketsOfActivityArgs = {
  input: TicketActivityInput;
};


export type QueryTicketsVolumeArgs = {
  input: TicketsVolumeInput;
};


export type QueryUserPaymasterArgs = {
  activityId: Scalars['Int']['input'];
};


export type QueryUserResponsibleArgs = {
  input: UserResponsibleInput;
};


export type QueryUsersRevenuesArgs = {
  input: PaginationInput;
};

export type RemoveCategoryOutput = {
  __typename?: 'RemoveCategoryOutput';
  children: Array<Category>;
  maskedProducts: Array<Product>;
};

export type RemoveDocumentOutput = {
  __typename?: 'RemoveDocumentOutput';
  document: Document;
  stocks: Array<Stock>;
};

export type RemoveResponsibleInput = {
  id: Scalars['String']['input'];
  leave: Scalars['Boolean']['input'];
};

export type ResetPasswordInput = {
  code: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Responsible = {
  __typename?: 'Responsible';
  accepted: Scalars['Boolean']['output'];
  activity: Activity;
  activityId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  inviter?: Maybe<User>;
  inviterId: Scalars['Int']['output'];
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['Int']['output']>;
  user: User;
  userId: Scalars['Int']['output'];
};

export type ResponsibleInput = {
  activityId: Scalars['Int']['input'];
};

export type ResponsiblePagination = {
  __typename?: 'ResponsiblePagination';
  items: Array<Responsible>;
  meta: Meta;
};

export type Role = {
  __typename?: 'Role';
  access: Array<Access>;
  activityId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  responsible: Array<Responsible>;
};

export type SalesRapportInput = {
  activityId: Scalars['Int']['input'];
  endAt: Scalars['DateTime']['input'];
  period?: InputMaybe<Scalars['String']['input']>;
  sequences: Array<Scalars['String']['input']>;
  startAt: Scalars['DateTime']['input'];
};

export type Sector = {
  __typename?: 'Sector';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
};

export type SendCodePinInput = {
  email: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type Sequence = {
  __typename?: 'Sequence';
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  closer?: Maybe<User>;
  expenses: Array<Expense>;
  id: Scalars['String']['output'];
  number: Scalars['Float']['output'];
  openedAt: Scalars['DateTime']['output'];
  opener?: Maybe<User>;
  terminal: Terminal;
  tickets: Array<Ticket>;
};

export type SequenceCategoriesRapport = {
  __typename?: 'SequenceCategoriesRapport';
  category: Scalars['String']['output'];
  inclTax: Scalars['Float']['output'];
};

export type SequenceCreateInput = {
  activityId: Scalars['Float']['input'];
  terminalId: Scalars['String']['input'];
};

export type SequenceCreditsRapport = {
  __typename?: 'SequenceCreditsRapport';
  amount: Scalars['Float']['output'];
  change?: Maybe<Scalars['Float']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  customerName: Scalars['String']['output'];
  discount: Scalars['Float']['output'];
  received?: Maybe<Scalars['Float']['output']>;
};

export type SequencePaginateInput = {
  activityId: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type SequencePagination = {
  __typename?: 'SequencePagination';
  items: Array<Sequence>;
  meta: Meta;
};

export type SequencePaymentsRapport = {
  __typename?: 'SequencePaymentsRapport';
  creditPaymentSpecies: Scalars['Float']['output'];
  creditPayments: Scalars['Float']['output'];
  credits: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  offer: Scalars['Float']['output'];
  payed: Scalars['Float']['output'];
  paymentsByTypes: Array<PaymentByType>;
  specialCreditPayments: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type SequenceProductRapportDto = {
  __typename?: 'SequenceProductRapportDto';
  inclTax: Scalars['Float']['output'];
  product: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
};

export type SequencesRapportOutput = {
  __typename?: 'SequencesRapportOutput';
  balance: Scalars['Float']['output'];
  cashingOut?: Maybe<Scalars['Float']['output']>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  income?: Maybe<Scalars['Float']['output']>;
  openedAt: Scalars['DateTime']['output'];
  sequence: Scalars['String']['output'];
  sequenceId: Scalars['String']['output'];
  terminal: Scalars['String']['output'];
  total?: Maybe<Scalars['Float']['output']>;
};

export type Stock = {
  __typename?: 'Stock';
  alertAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  exclTax: Scalars['Float']['output'];
  expiredAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inclTax: Scalars['Float']['output'];
  note: Scalars['String']['output'];
  product: Product;
  productId: Scalars['Float']['output'];
  purchasePrice: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vat: Scalars['Float']['output'];
};

export type StockInput = {
  alertAt?: InputMaybe<Scalars['String']['input']>;
  expiredAt?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Float']['input'];
};

export type StockMovementsOutput = {
  __typename?: 'StockMovementsOutput';
  balance: Scalars['Float']['output'];
  category: Scalars['String']['output'];
  categoryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  motif: Scalars['Int']['output'];
  movement: Scalars['Float']['output'];
  product: Scalars['String']['output'];
  productId: Scalars['Int']['output'];
};

export type StockPagination = {
  __typename?: 'StockPagination';
  items: Array<Stock>;
  meta: Meta;
};

export type SubscriptionPayment = {
  __typename?: 'SubscriptionPayment';
  activity: Activity;
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expiredAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  purchasedAt: Scalars['DateTime']['output'];
  receiptUrl: Scalars['String']['output'];
  status: SubscriptionPaymentEnum;
  subscription: _Subscription;
  subscriptionId: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  user: User;
};

export enum SubscriptionPaymentEnum {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Pending = 'pending',
  Refused = 'refused'
}

export type SubscriptionPaymentPagination = {
  __typename?: 'SubscriptionPaymentPagination';
  items: Array<SubscriptionPayment>;
  meta: Meta;
};

export type SubscriptionPaymentPaginationInput = {
  endAt: Scalars['DateTime']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  startAt: Scalars['DateTime']['input'];
};

export type Terminal = {
  __typename?: 'Terminal';
  active: Scalars['Int']['output'];
  activityId: Scalars['Int']['output'];
  allowDiscountLines: Scalars['Int']['output'];
  categories: Array<TerminalCategory>;
  codeBar: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastUse?: Maybe<Scalars['DateTime']['output']>;
  margeActive: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  printBarcode: Scalars['Int']['output'];
  printStockPos: Scalars['Int']['output'];
  printerMargins?: Maybe<Array<Scalars['Float']['output']>>;
  printerSettings?: Maybe<Array<Scalars['Float']['output']>>;
  userId?: Maybe<Scalars['Int']['output']>;
  users: Array<User>;
};

export type TerminalCategory = {
  __typename?: 'TerminalCategory';
  categoryId: Scalars['Int']['output'];
  checked: Scalars['Boolean']['output'];
  partialChecked: Scalars['Boolean']['output'];
};

export type TerminalCategoryInput = {
  categoryId: Scalars['Int']['input'];
  checked: Scalars['Boolean']['input'];
  partialChecked: Scalars['Boolean']['input'];
};

export type TerminalConnectedInput = {
  autoConnexion: Scalars['Boolean']['input'];
  connect: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  readonlyMode: Scalars['Boolean']['input'];
  timeout: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type TerminalsInput = {
  activityId: Scalars['Int']['input'];
};

export type Ticket = {
  __typename?: 'Ticket';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<Customer>;
  id: Scalars['String']['output'];
  movements: Array<Movement>;
  number?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  payments: Array<PaymentReceipt>;
  percentage: Scalars['Float']['output'];
  sequence?: Maybe<Sequence>;
  sequenceId: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type TicketActivityInput = {
  activityId: Scalars['Int']['input'];
  endAt: Scalars['String']['input'];
  startAt: Scalars['String']['input'];
};

export type TicketLineInput = {
  price: Scalars['Float']['input'];
  productId: Scalars['Int']['input'];
  quantity: Scalars['Float']['input'];
  stockId: Scalars['String']['input'];
};

export type TicketOverviewsOutput = {
  __typename?: 'TicketOverviewsOutput';
  count: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
};

export type TicketRecoverInput = {
  id: Scalars['String']['input'];
};

export type TicketRemoveInput = {
  activityId: Scalars['Int']['input'];
  id: Scalars['String']['input'];
  sequenceId: Scalars['String']['input'];
};

export type TicketRemoveOutput = {
  __typename?: 'TicketRemoveOutput';
  stocks: Array<Stock>;
  tickets: Array<Ticket>;
};

export type TicketsVolumeInput = {
  activityId: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type UpdateActivityInput = {
  address: Scalars['String']['input'];
  country: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  currencySymbol: Scalars['String']['input'];
  decimalNumber: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  removeImage: Scalars['Boolean']['input'];
  separator: Scalars['Int']['input'];
  slogan: Scalars['String']['input'];
  taxRate: Scalars['Float']['input'];
};

export type UpdateCategoryInput = {
  id: Scalars['Int']['input'];
  label: Scalars['String']['input'];
  parentId: Scalars['Int']['input'];
  removeImage: Scalars['Boolean']['input'];
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateEmployeeInput = {
  /** Contacts */
  contacts?: InputMaybe<Scalars['String']['input']>;
  /** CV */
  cv?: InputMaybe<Scalars['Upload']['input']>;
  /** Date d'entrée de l'employée */
  departureDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Date d'entrée de l'employée */
  entryDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  functionId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  /** Pièce d'identité */
  identity?: InputMaybe<Scalars['Upload']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  sectorId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateExpenseInput = {
  amount: Scalars['Float']['input'];
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  motif: Scalars['String']['input'];
};

export type UpdateFunctionInput = {
  id: Scalars['Int']['input'];
  /** Example field (placeholder) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type UpdateProductInput = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  changeable: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notStorableInput: NotStorableInput;
  reference?: InputMaybe<Scalars['String']['input']>;
  threshold?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateQuantitiesInput = {
  activityId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  input: Array<UpdateQuantityInput>;
  type: Scalars['Int']['input'];
};

export type UpdateQuantityInput = {
  id: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
};

export type UpdateRoleInput = {
  access: Array<AccessInput>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSectorInput = {
  id: Scalars['Int']['input'];
  /** name of sector */
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateStockInput = {
  activityId: Scalars['Int']['input'];
  alertAt?: InputMaybe<Scalars['String']['input']>;
  exclTax: Scalars['Float']['input'];
  expiredAt?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  inclTax: Scalars['Float']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  purchasePrice: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vat: Scalars['Float']['input'];
};

export type UpdateSubscriptionInput = {
  cost: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateTerminalInput = {
  active?: InputMaybe<Scalars['Int']['input']>;
  allowDiscountLines?: InputMaybe<Scalars['Int']['input']>;
  categories?: InputMaybe<Array<TerminalCategoryInput>>;
  codeBar?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  margeActive: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  printBarcode?: InputMaybe<Scalars['Int']['input']>;
  printStockPos?: InputMaybe<Scalars['Int']['input']>;
  printerMargins?: InputMaybe<Array<Scalars['Int']['input']>>;
  printerSettings?: InputMaybe<Array<Scalars['Int']['input']>>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateTicketInput = {
  activityId: Scalars['Int']['input'];
  amount: Scalars['Float']['input'];
  customerId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  payment?: InputMaybe<CreatePaymentReceiptInput>;
  percentage: Scalars['Float']['input'];
  sequenceId: Scalars['String']['input'];
  status: Scalars['Int']['input'];
};

export type UpdateUserInput = {
  language?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type User = {
  __typename?: 'User';
  activation: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  instigatorId?: Maybe<Scalars['Float']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  language: Scalars['Float']['output'];
  lastLoginAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  password: Scalars['String']['output'];
  resentTimes: Scalars['Int']['output'];
  resetPasswordCode: Scalars['Float']['output'];
  responsible?: Maybe<Array<Responsible>>;
  settings: Array<Scalars['Float']['output']>;
};

export type UserResponsibleInput = {
  userAccountId?: InputMaybe<Scalars['Int']['input']>;
};

export type UserRevenue = {
  __typename?: 'UserRevenue';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  instigatorId?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  revenue?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Int']['output'];
};

export type UserUpdateByAdminInput = {
  activation: Scalars['Float']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  instigatorId?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type UsersRevenuePagination = {
  __typename?: 'UsersRevenuePagination';
  items: Array<UserRevenue>;
  meta: Meta;
};

export type VerifyUserInput = {
  code: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type _Subscription = {
  __typename?: '_Subscription';
  cost: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type ActivityModelFragment = { __typename?: 'Activity', id: number, name: string, slogan: string, address: string, taxRate: number, separator: number, logo: string, country: number, decimalNumber: number, currency: string, currencySymbol: string, ownerId: number, createdAt: any };

export type LoginMutationVariables = Exact<{
  input: AuthInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginDto', token: string, user: { __typename?: 'User', id: number, name: string, isAdmin: boolean, lastLoginAt: any, resetPasswordCode: number, activation: number, language: number, settings: Array<number>, email: string, image: string, createdAt: any, responsible?: Array<{ __typename?: 'Responsible', id: string, roleId?: number | null, accepted: boolean, createdAt: any, activity: { __typename?: 'Activity', id: number, name: string, slogan: string, address: string, taxRate: number, separator: number, logo: string, country: number, decimalNumber: number, currency: string, currencySymbol: string, ownerId: number, createdAt: any, payments: Array<{ __typename?: 'SubscriptionPayment', id: string, subscriptionId: number, amount: number, currency: string, transactionId: string, purchasedAt: any, expiredAt: any }> }, inviter?: { __typename?: 'User', id: number, name: string, isAdmin: boolean, lastLoginAt: any, resetPasswordCode: number, activation: number, language: number, settings: Array<number>, email: string, image: string, createdAt: any } | null, role?: { __typename?: 'Role', id: number, name: string, activityId?: number | null, access: Array<{ __typename?: 'Access', menu: EAccessMenu, create: boolean, read: boolean, update: boolean, delete: boolean }> } | null }> | null } } };

export type CreateEmployeeMutationVariables = Exact<{
  input: CreateEmployeeInput;
}>;


export type CreateEmployeeMutation = { __typename?: 'Mutation', createEmployee: { __typename?: 'Employee', id: number, firstName: string, lastName: string, entryDate?: any | null, departureDate?: any | null, identity: string, cv: string, contacts: string, createdAt: any, function?: { __typename?: 'Fonction', id: number, name: string, createdAt: any } | null, sector?: { __typename?: 'Sector', id: number, name: string, parentId?: number | null, createdAt: any } | null } };

export type EmployeeModelFragment = { __typename?: 'Employee', id: number, firstName: string, lastName: string, entryDate?: any | null, departureDate?: any | null, identity: string, cv: string, contacts: string, createdAt: any, function?: { __typename?: 'Fonction', id: number, name: string, createdAt: any } | null, sector?: { __typename?: 'Sector', id: number, name: string, parentId?: number | null, createdAt: any } | null };

export type EmployeesQueryVariables = Exact<{
  activityId: Scalars['Int']['input'];
}>;


export type EmployeesQuery = { __typename?: 'Query', employees: Array<{ __typename?: 'Employee', id: number, firstName: string, lastName: string, entryDate?: any | null, departureDate?: any | null, identity: string, cv: string, contacts: string, createdAt: any, function?: { __typename?: 'Fonction', id: number, name: string, createdAt: any } | null, sector?: { __typename?: 'Sector', id: number, name: string, parentId?: number | null, createdAt: any } | null }> };

export type RemoveEmployeeMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RemoveEmployeeMutation = { __typename?: 'Mutation', removeEmployee: number };

export type UpdateEmployeeMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'Employee', id: number, firstName: string, lastName: string, entryDate?: any | null, departureDate?: any | null, identity: string, cv: string, contacts: string, createdAt: any, function?: { __typename?: 'Fonction', id: number, name: string, createdAt: any } | null, sector?: { __typename?: 'Sector', id: number, name: string, parentId?: number | null, createdAt: any } | null } };

export type CreateFunctionMutationVariables = Exact<{
  input: CreateFunctionInput;
}>;


export type CreateFunctionMutation = { __typename?: 'Mutation', createFunction: { __typename?: 'Fonction', id: number, name: string, createdAt: any } };

export type FunctionModelFragment = { __typename?: 'Fonction', id: number, name: string, createdAt: any };

export type FunctionsQueryVariables = Exact<{
  activityId: Scalars['Int']['input'];
}>;


export type FunctionsQuery = { __typename?: 'Query', functions: Array<{ __typename?: 'Fonction', id: number, name: string, createdAt: any }> };

export type RemoveFunctionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RemoveFunctionMutation = { __typename?: 'Mutation', removeFunction: number };

export type UpdateFunctionMutationVariables = Exact<{
  input: UpdateFunctionInput;
}>;


export type UpdateFunctionMutation = { __typename?: 'Mutation', updateFunction: { __typename?: 'Fonction', id: number, name: string, createdAt: any } };

export type CreateSectorMutationVariables = Exact<{
  input: CreateSectorInput;
}>;


export type CreateSectorMutation = { __typename?: 'Mutation', createSector: { __typename?: 'Sector', id: number, name: string, parentId?: number | null, createdAt: any } };

export type RemoveSectorMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RemoveSectorMutation = { __typename?: 'Mutation', removeSector: number };

export type SectorsQueryVariables = Exact<{
  activityId: Scalars['Int']['input'];
}>;


export type SectorsQuery = { __typename?: 'Query', sectors: Array<{ __typename?: 'Sector', id: number, name: string, parentId?: number | null, createdAt: any }> };

export type SectorModelFragment = { __typename?: 'Sector', id: number, name: string, parentId?: number | null, createdAt: any };

export type UpdateSectorMutationVariables = Exact<{
  input: UpdateSectorInput;
}>;


export type UpdateSectorMutation = { __typename?: 'Mutation', updateSector: { __typename?: 'Sector', id: number, name: string, parentId?: number | null, createdAt: any } };

export type ResponsibleModelFragment = { __typename?: 'Responsible', id: string, roleId?: number | null, accepted: boolean, createdAt: any, inviter?: { __typename?: 'User', id: number, name: string, isAdmin: boolean, lastLoginAt: any, resetPasswordCode: number, activation: number, language: number, settings: Array<number>, email: string, image: string, createdAt: any } | null, role?: { __typename?: 'Role', id: number, name: string, activityId?: number | null, access: Array<{ __typename?: 'Access', menu: EAccessMenu, create: boolean, read: boolean, update: boolean, delete: boolean }> } | null };

export type UserResponsibleQueryVariables = Exact<{
  input: UserResponsibleInput;
}>;


export type UserResponsibleQuery = { __typename?: 'Query', userResponsible: Array<{ __typename?: 'Responsible', id: string, roleId?: number | null, accepted: boolean, createdAt: any, activity: { __typename?: 'Activity', id: number, name: string, slogan: string, address: string, taxRate: number, separator: number, logo: string, country: number, decimalNumber: number, currency: string, currencySymbol: string, ownerId: number, createdAt: any, payments: Array<{ __typename?: 'SubscriptionPayment', id: string, subscriptionId: number, amount: number, currency: string, transactionId: string, purchasedAt: any, expiredAt: any }> }, inviter?: { __typename?: 'User', id: number, name: string, isAdmin: boolean, lastLoginAt: any, resetPasswordCode: number, activation: number, language: number, settings: Array<number>, email: string, image: string, createdAt: any } | null, role?: { __typename?: 'Role', id: number, name: string, activityId?: number | null, access: Array<{ __typename?: 'Access', menu: EAccessMenu, create: boolean, read: boolean, update: boolean, delete: boolean }> } | null }> };

export type AccessModelFragment = { __typename?: 'Access', menu: EAccessMenu, create: boolean, read: boolean, update: boolean, delete: boolean };

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', id: number, name: string, activityId?: number | null, access: Array<{ __typename?: 'Access', menu: EAccessMenu, create: boolean, read: boolean, update: boolean, delete: boolean }> } };

export type RemoveRoleMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RemoveRoleMutation = { __typename?: 'Mutation', removeRole: number };

export type RolesQueryVariables = Exact<{
  activityId: Scalars['Int']['input'];
}>;


export type RolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', id: number, name: string, activityId?: number | null, access: Array<{ __typename?: 'Access', menu: EAccessMenu, create: boolean, read: boolean, update: boolean, delete: boolean }> }> };

export type RoleModelFragment = { __typename?: 'Role', id: number, name: string, activityId?: number | null, access: Array<{ __typename?: 'Access', menu: EAccessMenu, create: boolean, read: boolean, update: boolean, delete: boolean }> };

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'Role', id: number, name: string, activityId?: number | null, access: Array<{ __typename?: 'Access', menu: EAccessMenu, create: boolean, read: boolean, update: boolean, delete: boolean }> } };

export type SubscriptionPaymentModelFragment = { __typename?: 'SubscriptionPayment', id: string, subscriptionId: number, amount: number, currency: string, transactionId: string, purchasedAt: any, expiredAt: any };

export type UserModelFragment = { __typename?: 'User', id: number, name: string, isAdmin: boolean, lastLoginAt: any, resetPasswordCode: number, activation: number, language: number, settings: Array<number>, email: string, image: string, createdAt: any };

export type WhoAmiQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmiQuery = { __typename?: 'Query', whoAmI: { __typename?: 'User', id: number, name: string, isAdmin: boolean, lastLoginAt: any, resetPasswordCode: number, activation: number, language: number, settings: Array<number>, email: string, image: string, createdAt: any } };

export const ActivityModelFragmentDoc = gql`
    fragment ActivityModel on Activity {
  id
  name
  slogan
  address
  taxRate
  separator
  logo
  country
  decimalNumber
  currency
  currencySymbol
  ownerId
  createdAt
}
    `;
export const FunctionModelFragmentDoc = gql`
    fragment FunctionModel on Fonction {
  id
  name
  createdAt
}
    `;
export const SectorModelFragmentDoc = gql`
    fragment SectorModel on Sector {
  id
  name
  parentId
  createdAt
}
    `;
export const EmployeeModelFragmentDoc = gql`
    fragment EmployeeModel on Employee {
  id
  firstName
  lastName
  entryDate
  departureDate
  identity
  cv
  contacts
  function {
    ...FunctionModel
  }
  sector {
    ...SectorModel
  }
  createdAt
}
    ${FunctionModelFragmentDoc}
${SectorModelFragmentDoc}`;
export const UserModelFragmentDoc = gql`
    fragment UserModel on User {
  id
  name
  isAdmin
  lastLoginAt
  resetPasswordCode
  activation
  language
  settings
  email
  image
  createdAt
}
    `;
export const AccessModelFragmentDoc = gql`
    fragment AccessModel on Access {
  menu
  create
  read
  update
  delete
}
    `;
export const RoleModelFragmentDoc = gql`
    fragment RoleModel on Role {
  id
  name
  activityId
  access {
    ...AccessModel
  }
}
    ${AccessModelFragmentDoc}`;
export const ResponsibleModelFragmentDoc = gql`
    fragment ResponsibleModel on Responsible {
  id
  inviter {
    ...UserModel
  }
  roleId
  role {
    ...RoleModel
  }
  accepted
  createdAt
}
    ${UserModelFragmentDoc}
${RoleModelFragmentDoc}`;
export const SubscriptionPaymentModelFragmentDoc = gql`
    fragment SubscriptionPaymentModel on SubscriptionPayment {
  id
  subscriptionId
  amount
  currency
  transactionId
  purchasedAt
  expiredAt
}
    `;
export const LoginDocument = gql`
    mutation Login($input: AuthInput!) {
  login(input: $input) {
    token
    user {
      ...UserModel
      responsible {
        ...ResponsibleModel
        activity {
          ...ActivityModel
          payments {
            ...SubscriptionPaymentModel
          }
        }
      }
    }
  }
}
    ${UserModelFragmentDoc}
${ResponsibleModelFragmentDoc}
${ActivityModelFragmentDoc}
${SubscriptionPaymentModelFragmentDoc}`;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLoginMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(options: VueApolloComposable.UseMutationOptions<LoginMutation, LoginMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<LoginMutation, LoginMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<LoginMutation, LoginMutationVariables>;
export const CreateEmployeeDocument = gql`
    mutation CreateEmployee($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    ...EmployeeModel
  }
}
    ${EmployeeModelFragmentDoc}`;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateEmployeeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(options: VueApolloComposable.UseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, options);
}
export type CreateEmployeeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const EmployeesDocument = gql`
    query Employees($activityId: Int!) {
  employees(activityId: $activityId) {
    ...EmployeeModel
  }
}
    ${EmployeeModelFragmentDoc}`;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a Vue component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEmployeesQuery({
 *   activityId: // value for 'activityId'
 * });
 */
export function useEmployeesQuery(variables: EmployeesQueryVariables | VueCompositionApi.Ref<EmployeesQueryVariables> | ReactiveFunction<EmployeesQueryVariables>, options: VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, variables, options);
}
export function useEmployeesLazyQuery(variables?: EmployeesQueryVariables | VueCompositionApi.Ref<EmployeesQueryVariables> | ReactiveFunction<EmployeesQueryVariables>, options: VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, variables, options);
}
export type EmployeesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EmployeesQuery, EmployeesQueryVariables>;
export const RemoveEmployeeDocument = gql`
    mutation RemoveEmployee($id: Int!) {
  removeEmployee(id: $id)
}
    `;

/**
 * __useRemoveEmployeeMutation__
 *
 * To run a mutation, you first call `useRemoveEmployeeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEmployeeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveEmployeeMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEmployeeMutation(options: VueApolloComposable.UseMutationOptions<RemoveEmployeeMutation, RemoveEmployeeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveEmployeeMutation, RemoveEmployeeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveEmployeeMutation, RemoveEmployeeMutationVariables>(RemoveEmployeeDocument, options);
}
export type RemoveEmployeeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveEmployeeMutation, RemoveEmployeeMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    ...EmployeeModel
  }
}
    ${EmployeeModelFragmentDoc}`;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateEmployeeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(options: VueApolloComposable.UseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
}
export type UpdateEmployeeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const CreateFunctionDocument = gql`
    mutation CreateFunction($input: CreateFunctionInput!) {
  createFunction(input: $input) {
    ...FunctionModel
  }
}
    ${FunctionModelFragmentDoc}`;

/**
 * __useCreateFunctionMutation__
 *
 * To run a mutation, you first call `useCreateFunctionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateFunctionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateFunctionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateFunctionMutation(options: VueApolloComposable.UseMutationOptions<CreateFunctionMutation, CreateFunctionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateFunctionMutation, CreateFunctionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateFunctionMutation, CreateFunctionMutationVariables>(CreateFunctionDocument, options);
}
export type CreateFunctionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateFunctionMutation, CreateFunctionMutationVariables>;
export const FunctionsDocument = gql`
    query Functions($activityId: Int!) {
  functions(activityId: $activityId) {
    ...FunctionModel
  }
}
    ${FunctionModelFragmentDoc}`;

/**
 * __useFunctionsQuery__
 *
 * To run a query within a Vue component, call `useFunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunctionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFunctionsQuery({
 *   activityId: // value for 'activityId'
 * });
 */
export function useFunctionsQuery(variables: FunctionsQueryVariables | VueCompositionApi.Ref<FunctionsQueryVariables> | ReactiveFunction<FunctionsQueryVariables>, options: VueApolloComposable.UseQueryOptions<FunctionsQuery, FunctionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FunctionsQuery, FunctionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FunctionsQuery, FunctionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FunctionsQuery, FunctionsQueryVariables>(FunctionsDocument, variables, options);
}
export function useFunctionsLazyQuery(variables?: FunctionsQueryVariables | VueCompositionApi.Ref<FunctionsQueryVariables> | ReactiveFunction<FunctionsQueryVariables>, options: VueApolloComposable.UseQueryOptions<FunctionsQuery, FunctionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FunctionsQuery, FunctionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FunctionsQuery, FunctionsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FunctionsQuery, FunctionsQueryVariables>(FunctionsDocument, variables, options);
}
export type FunctionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FunctionsQuery, FunctionsQueryVariables>;
export const RemoveFunctionDocument = gql`
    mutation RemoveFunction($id: Int!) {
  removeFunction(id: $id)
}
    `;

/**
 * __useRemoveFunctionMutation__
 *
 * To run a mutation, you first call `useRemoveFunctionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFunctionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveFunctionMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFunctionMutation(options: VueApolloComposable.UseMutationOptions<RemoveFunctionMutation, RemoveFunctionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveFunctionMutation, RemoveFunctionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveFunctionMutation, RemoveFunctionMutationVariables>(RemoveFunctionDocument, options);
}
export type RemoveFunctionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveFunctionMutation, RemoveFunctionMutationVariables>;
export const UpdateFunctionDocument = gql`
    mutation UpdateFunction($input: UpdateFunctionInput!) {
  updateFunction(input: $input) {
    ...FunctionModel
  }
}
    ${FunctionModelFragmentDoc}`;

/**
 * __useUpdateFunctionMutation__
 *
 * To run a mutation, you first call `useUpdateFunctionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFunctionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateFunctionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFunctionMutation(options: VueApolloComposable.UseMutationOptions<UpdateFunctionMutation, UpdateFunctionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateFunctionMutation, UpdateFunctionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateFunctionMutation, UpdateFunctionMutationVariables>(UpdateFunctionDocument, options);
}
export type UpdateFunctionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateFunctionMutation, UpdateFunctionMutationVariables>;
export const CreateSectorDocument = gql`
    mutation CreateSector($input: CreateSectorInput!) {
  createSector(input: $input) {
    ...SectorModel
  }
}
    ${SectorModelFragmentDoc}`;

/**
 * __useCreateSectorMutation__
 *
 * To run a mutation, you first call `useCreateSectorMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSectorMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSectorMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateSectorMutation(options: VueApolloComposable.UseMutationOptions<CreateSectorMutation, CreateSectorMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateSectorMutation, CreateSectorMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateSectorMutation, CreateSectorMutationVariables>(CreateSectorDocument, options);
}
export type CreateSectorMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateSectorMutation, CreateSectorMutationVariables>;
export const RemoveSectorDocument = gql`
    mutation RemoveSector($id: Int!) {
  removeSector(id: $id)
}
    `;

/**
 * __useRemoveSectorMutation__
 *
 * To run a mutation, you first call `useRemoveSectorMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSectorMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveSectorMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSectorMutation(options: VueApolloComposable.UseMutationOptions<RemoveSectorMutation, RemoveSectorMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveSectorMutation, RemoveSectorMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveSectorMutation, RemoveSectorMutationVariables>(RemoveSectorDocument, options);
}
export type RemoveSectorMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveSectorMutation, RemoveSectorMutationVariables>;
export const SectorsDocument = gql`
    query Sectors($activityId: Int!) {
  sectors(activityId: $activityId) {
    ...SectorModel
  }
}
    ${SectorModelFragmentDoc}`;

/**
 * __useSectorsQuery__
 *
 * To run a query within a Vue component, call `useSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectorsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSectorsQuery({
 *   activityId: // value for 'activityId'
 * });
 */
export function useSectorsQuery(variables: SectorsQueryVariables | VueCompositionApi.Ref<SectorsQueryVariables> | ReactiveFunction<SectorsQueryVariables>, options: VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SectorsQuery, SectorsQueryVariables>(SectorsDocument, variables, options);
}
export function useSectorsLazyQuery(variables?: SectorsQueryVariables | VueCompositionApi.Ref<SectorsQueryVariables> | ReactiveFunction<SectorsQueryVariables>, options: VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SectorsQuery, SectorsQueryVariables>(SectorsDocument, variables, options);
}
export type SectorsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SectorsQuery, SectorsQueryVariables>;
export const UpdateSectorDocument = gql`
    mutation UpdateSector($input: UpdateSectorInput!) {
  updateSector(input: $input) {
    ...SectorModel
  }
}
    ${SectorModelFragmentDoc}`;

/**
 * __useUpdateSectorMutation__
 *
 * To run a mutation, you first call `useUpdateSectorMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSectorMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSectorMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSectorMutation(options: VueApolloComposable.UseMutationOptions<UpdateSectorMutation, UpdateSectorMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateSectorMutation, UpdateSectorMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateSectorMutation, UpdateSectorMutationVariables>(UpdateSectorDocument, options);
}
export type UpdateSectorMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateSectorMutation, UpdateSectorMutationVariables>;
export const UserResponsibleDocument = gql`
    query UserResponsible($input: UserResponsibleInput!) {
  userResponsible(input: $input) {
    ...ResponsibleModel
    activity {
      ...ActivityModel
      payments {
        ...SubscriptionPaymentModel
      }
    }
  }
}
    ${ResponsibleModelFragmentDoc}
${ActivityModelFragmentDoc}
${SubscriptionPaymentModelFragmentDoc}`;

/**
 * __useUserResponsibleQuery__
 *
 * To run a query within a Vue component, call `useUserResponsibleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserResponsibleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserResponsibleQuery({
 *   input: // value for 'input'
 * });
 */
export function useUserResponsibleQuery(variables: UserResponsibleQueryVariables | VueCompositionApi.Ref<UserResponsibleQueryVariables> | ReactiveFunction<UserResponsibleQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserResponsibleQuery, UserResponsibleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserResponsibleQuery, UserResponsibleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserResponsibleQuery, UserResponsibleQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UserResponsibleQuery, UserResponsibleQueryVariables>(UserResponsibleDocument, variables, options);
}
export function useUserResponsibleLazyQuery(variables?: UserResponsibleQueryVariables | VueCompositionApi.Ref<UserResponsibleQueryVariables> | ReactiveFunction<UserResponsibleQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserResponsibleQuery, UserResponsibleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserResponsibleQuery, UserResponsibleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserResponsibleQuery, UserResponsibleQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UserResponsibleQuery, UserResponsibleQueryVariables>(UserResponsibleDocument, variables, options);
}
export type UserResponsibleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UserResponsibleQuery, UserResponsibleQueryVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    ...RoleModel
  }
}
    ${RoleModelFragmentDoc}`;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateRoleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(options: VueApolloComposable.UseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
}
export type CreateRoleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateRoleMutation, CreateRoleMutationVariables>;
export const RemoveRoleDocument = gql`
    mutation RemoveRole($id: Int!) {
  removeRole(id: $id)
}
    `;

/**
 * __useRemoveRoleMutation__
 *
 * To run a mutation, you first call `useRemoveRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveRoleMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRoleMutation(options: VueApolloComposable.UseMutationOptions<RemoveRoleMutation, RemoveRoleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveRoleMutation, RemoveRoleMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveRoleMutation, RemoveRoleMutationVariables>(RemoveRoleDocument, options);
}
export type RemoveRoleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveRoleMutation, RemoveRoleMutationVariables>;
export const RolesDocument = gql`
    query Roles($activityId: Int!) {
  roles(activityId: $activityId) {
    ...RoleModel
  }
}
    ${RoleModelFragmentDoc}`;

/**
 * __useRolesQuery__
 *
 * To run a query within a Vue component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useRolesQuery({
 *   activityId: // value for 'activityId'
 * });
 */
export function useRolesQuery(variables: RolesQueryVariables | VueCompositionApi.Ref<RolesQueryVariables> | ReactiveFunction<RolesQueryVariables>, options: VueApolloComposable.UseQueryOptions<RolesQuery, RolesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<RolesQuery, RolesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<RolesQuery, RolesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, variables, options);
}
export function useRolesLazyQuery(variables?: RolesQueryVariables | VueCompositionApi.Ref<RolesQueryVariables> | ReactiveFunction<RolesQueryVariables>, options: VueApolloComposable.UseQueryOptions<RolesQuery, RolesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<RolesQuery, RolesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<RolesQuery, RolesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, variables, options);
}
export type RolesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<RolesQuery, RolesQueryVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    ...RoleModel
  }
}
    ${RoleModelFragmentDoc}`;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateRoleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(options: VueApolloComposable.UseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
}
export type UpdateRoleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const WhoAmiDocument = gql`
    query WhoAmi {
  whoAmI {
    ...UserModel
  }
}
    ${UserModelFragmentDoc}`;

/**
 * __useWhoAmiQuery__
 *
 * To run a query within a Vue component, call `useWhoAmiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmiQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWhoAmiQuery();
 */
export function useWhoAmiQuery(options: VueApolloComposable.UseQueryOptions<WhoAmiQuery, WhoAmiQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WhoAmiQuery, WhoAmiQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WhoAmiQuery, WhoAmiQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WhoAmiQuery, WhoAmiQueryVariables>(WhoAmiDocument, {}, options);
}
export function useWhoAmiLazyQuery(options: VueApolloComposable.UseQueryOptions<WhoAmiQuery, WhoAmiQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WhoAmiQuery, WhoAmiQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WhoAmiQuery, WhoAmiQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<WhoAmiQuery, WhoAmiQueryVariables>(WhoAmiDocument, {}, options);
}
export type WhoAmiQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WhoAmiQuery, WhoAmiQueryVariables>;