export default {
  description: "Description",
  newTerminal: "Nouveau terminal",
  updateTerminal: "Mise à jour du terminal",
  number: "Terminal {number}",
  saveChanges: "Enregistrer les modifications",
  create: {
    success: "Terminal crée avec succès",
    failed: "Echec de création du terminal!",
  },
  update: {
    success: "Terminal mis à jour avec succès",
    failed: "Mise à jour du terminal echouée!",
  },
  status: "Statut du terminal",
  enabled: "Activé",
  disabled: "Desactivé",
  printerSettings: [
    "Imprimer un ticket avant l'encaissement",
    "Imprimer un ticket sans prix",
    "Imprimer un ticket partiel",
    "Imprimer un ticket partiel et sans prix",
    "Imprimer un ticket épuré (sans en-têtes et pieds de page)",
    "Imprimer un ticket partiel et épuré",
  ],
  printerOptions: [
    "Imprimer le ticket",
    "Imprimer sans prix",
    "Impression partielle",
    "Impression patielle & sans prix",
    "Impression de ticket épuré",
    "Impression partielle et épurée",
  ],
  access: "Accès au POS",
  notFound:
    "Il n'y a aucun terminal définit dans les paramètres de l'activité.",
  noAccess: "Accès au terminal non autorisé!",
  categorySettings: "Paramètrage de catégories",
  selectCategories: "Cochez les catégories visibles dans ce terminal",
  userSettings: "Gestion d'accès des caissiers",
  selectUsers: "Cochez les caissiers autorisés à accéder à ce terminal",
  details: "Détails",
  printerSetting: "Paramètres d'imprimante",
  margins: "Marges lors de l'impression",
  reset: "Restaurer par défaut",
  top: "Haut",
  right: "Droite",
  bottom: "Bas",
  left: "Gauche",
  customize: "Personnaliser",
  customizeTitle: "Personnalisation des marges",
  apply: "Appliquer",
  test: "Tester",
  enable: "Activer",
  disable: "Désactiver",
  warningMarge:
    "Attention, ne modifiez que si vous avez un problème avec les marges d'impression.",
  choose: "Sélectionnez un terminal",
  hasActiveSequence:
    "Impossible de désactiver ce terminal car une session est actuellement ouverte. Veuillez d'abord clôturer la session en cours.",
  codeBar: "Lecteur de code barre",
  printCodeBarTicket: "Afficher les code barre sur les tickets",
  leave: "Continuer en lecture seule",
  printStock: "Afficher le stock dans le POS",
  allowDiscountLines: "Autoriser les lignes de remise",
  addDiscountLine: "Ajouter une ligne de remise",
  manage: "Gérez les terminaux",
};
