
import { computed, defineComponent, PropType } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { numberSeparators } from "@/graphql/utils/utils";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "NumberFormatter",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    format: {
      type: String as PropType<"decimal" | "currency" | "percent">,
      default: "decimal",
    },
    discount: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { n } = useI18n();
    return {
      formattedValue: computed(() => {
        const local = numberSeparators[activeActivity.value.separator];
        const value = Number(props.value || 0);
        return props.discount
          ? value.toFixed(2)
          : n(
              Number(value.toFixed(activeActivity.value.decimalNumber)),
              `${props.format}`,
              local
            );
      }),
    };
  },
});
