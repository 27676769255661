export default {
  startsWith: "Commence par",
  contains: "Contient",
  notContains: "Ne contient pas",
  endsWith: "Se termine par",
  equals: "Egale à",
  notEquals: "Différent de",
  in: "Dans",
  lt: "Inférieur à ",
  lte: "Inférieur ou égale",
  gt: "Supérieur à",
  gte: "Supérieur ou égale",
  between: "Entre",
  dateIs: "Date est",
  dateIsNot: "Date n'est pas",
  dateBefore: "Date avant",
  dateAfter: "Date après",
  clear: "Effacer",
  apply: "Appliquer",
  matchAll: "Tout correspondre",
  matchAny: "Correspondre à n'importe quel",
  addRule: "Ajouter une règle",
  removeRule: "Supprimer la règle",
  accept: "Oui",
  reject: "Non",
  choose: "Choisir",
  upload: "Upload",
  cancel: "Annuler",
  dayNames: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ],
  dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  monthNamesShort: [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Jun",
    "Jui",
    "Aoû",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ],
  today: "Aujourd'hui",
  weekHeader: "Wk",
  firstDayOfWeek: "0",
  dateFormat: "mm/dd/yy",
  weak: "Faible",
  medium: "Moyen",
  strong: "Fort",
  passwordPrompt: "Entrer un mot de passe",
  emptyFilterMessage: "Aucun résultat trouvé",
  emptyMessage: "Aucune option disponible",
};
