import {
  ApolloClient,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloLink } from "@apollo/client/core";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { connexionLost } from "@/graphql/utils/connexion-lost-dialog";
import { logout } from "@/graphql/session";
import { CONSTANTS } from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";
/*import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";*/

const authLink = setContext((_, { headers, ...context }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${
        localStorage.getItem(CONSTANTS.token) as string
      }`,
      activity: activeActivity.value.id,
    },
    ...context,
  };
});

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

const httpOptions = {
  uri: `${process.env.VUE_APP_API}graphql`,
};

const httpLink = new HttpLink(httpOptions);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  let error = "";
  if (graphQLErrors)
    graphQLErrors.map(({ message }) => {
      return (error = String(message));
    });
  if (error.toLowerCase() === "unauthorized") logout(true);
  connexionLost.value = !!networkError;
});

const completedLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (!response.errors) connexionLost.value = false;
    return response;
  });
});

/*const retryLink = new RetryLink({
  attempts: {
    max: 3, // Maximum number of retries
    retryIf: (error, _operation) => {
      const automaticQueries = ["TerminalConnexion", "ActiveSequence"];
      return !!error && !automaticQueries.includes(_operation.operationName);
    }, // Retry if there is a network error and not automatic query
  },
  delay: {
    initial: 300, // Initial delay in milliseconds
    max: 300, // Maximum delay between retries
    jitter: true, // Apply random jitter to the delays
  },
});*/

// Create the subscription websocket link
/*const wbsLink = new WebSocketLink(
  new SubscriptionClient(
    httpOptions.uri.replace("http", "ws").replace("graphql", "subscriptions"),
    {
      connectionParams: {
        authToken: `Bearer ${localStorage.getItem(CONSTANTS.token) as string}`,
      },
      reconnect: true,
    }
  )
);
const wsLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as any;
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wbsLink
);*/

const uploadLink = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink(httpOptions) as any
);

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: errorLink
    //.concat(retryLink)
    .concat(completedLink)
    .concat(authLink)
    .concat(uploadLink)
    //.concat(wsLink)
    .concat(httpLink),
  cache: new InMemoryCache({ addTypename: true }), // Cache implementation
  connectToDevTools: process.env.NODE_ENV === "development",
  defaultOptions,
});
