export default {
  date: "DD-MM-YYYY",
  dateTime: "DD/MM/YYYY, HH:mm:SS",
  calendarDate: "dd-mm-yy",
  calShort: "yy-mm-dd",
  range: "dd/mm/yy",
  calLong: "dd-mm-yy",
  dateLong: "DD-MM-YYYY HH:mm:SS",
  separators: ["Français", "Anglais"],
  ticketDate: "DD/MM/YYYY - HH:mm",
};
