export default {
  name: "Nom",
  slogan: "Slogan",
  address: "Adresse",
  country: "Pays",
  numberFormat: "Format du nombre",
  thousandSeparator: "Séparateur de millier",
  decimalSeparator: "Séparateur décimal",
  decimalNumber: "Nombre décimal",
  currency: "Devise",
  currencySymbol: "Symbole",
  taxRate: "Taux de TVA",
  separator: "Séparateurs",
  filterByName: "Filtrer par nom",
  preview: "Aperçu",
  countries: [
    {
      label: "Cote d’ivoire",
      currency: "XOF",
      symbol: "CFA",
      separator: "0",
    },
    {
      label: "Mali",
      currency: "XOF",
      symbol: "F",
      separator: "0",
    },
    { label: "Sénégal", currency: "XOF", symbol: "F", separator: "0" },
    { label: "Togo", currency: "XOF", symbol: "CFA", separator: "0" },
    {
      label: "Burkina Faso",
      currency: "XOF",
      symbol: "CFA",
      separator: "0",
    },
    { label: "Bénin", currency: "XOF", symbol: "FCFA", separator: "0" },
    /*{ label: "Nijar", currency: "Franc CFA", symbol: "XOF", separator: "0" },
    { label: "Cameroun", currency: "Franc CFA", symbol: "XAF", separator: "0" },
    {
       label: "Congo RDC CDF",
       currency: "Franc congolais",
       separator: "0",
       symbol: "CDF",
     },
     {
       label: "Congo RDC USD",
       currency: "Dollar",
       symbol: "USD",
       separator: "1",
     },
     {
       label: "Guinée",
       currency: "Franc guinéen",
       symbol: "GNF",
       separator: "0",
     },
     {
       label: "Comores",
       currency: "Franc comorien",
       symbol: "KMF",
       separator: "0",
     },*/
  ],
  placeholder: "Séléctionner un pays",
  create: {
    title: "Création d'une nouvelle activité",
    subtitle: "Vous disposerez de 30 jours d'utilisation gratuite.",
    btn: "Créer une activité",
  },
  emptyFilterMessage: "Aucun résultat trouvé",
  emptyMessage: "Aucune activité disponible",
  owner: "Propriétaire",
  all: "Tout",
  youAre: "Vous êtes",
  thanksVisit:
    "vous remercie de votre visite et espere vous revoir bientôt ...",
  none: "Vous n'avez créée aucune activité",
  list: "Retrouvez vos activités",
  active: "Activité séléctionnée",
  noSelected: "Aucune activité séléctionnée",
  activated: "Connectée",
  invitations: "Vos invitations",
  as: "En tant que",
  inviter: "Inviteur",
  activity: "Activité",
  activityName: "Nom de l'activité",
  actions: "Actions",
  delete: {
    warning:
      "Cette activité et toutes les données liées à celle-ci seront définitivement supprimées.",
    success: "L'activité a été supprimée",
    failed: "Vous n'êtes pas autorisé(e) à supprimer cette activité",
    read: "Lisez attentivement !",
    checked: `En supprimant cette activité, je comprends que toutes ses données (catégories, produits, stocks, clients, ventes, dépenses et rapports) seront définitivement supprimées et cette action est irreversible.`,
  },
  exist: "Vous ne pouvez pas créer deux activités portant un même nom.",
  remainDays: "Expire dans {left}",
  exceededDays:
    "Votre abonnement est expiré depuis le {date}. Pour continuer à utiliser cette activité, veuillez acheter un abonnement.",
  expiredToday:
    "Votre abonnement est expiré à partir d'aujourd'hui. Pour continuer à utiliser cette activité, veuillez acheter un abonnement.",
  days: ["jour", "jours"],
  manageSubscription: "Mon abonnement",
  testDuration: "Durrée : 30 jours",
  chooseSubscription: "Sélectionnez une période d'abonnement",
  subscribe: {
    current: "Abonnement en cours",
    success: "Votre abonnement a été renouvellé",
    failed: "Désolé! Une erreure est survenue. Vous devez nous contacter!",
    purchase: "Acheter un abonnement",
    process: "Paiement",
    active: "Actif",
    inactive: "Inactif",
  },
  quit: {
    button: "Quitter l'activité",
    warning:
      "Etes vous sûr de vouloir quitter cette activité ? Vous n’aurez plus aucun accès à celle-ci.",
    attention: "Attention",
  },
  renewSubscription: "Renouveler mon abonnement",
  expirationMessage:
    " Les fonctionnalités de votre logiciel de caisse sont désactivées car votre abonnement est expiré. Veuillez renouveler votre abonnement.",
  reset: {
    title: "Réinitialisation de l'activité",
    warning:
      "Attention, la réinitialisation va effacer définitivement toutes les ventes.",
    also: "Effacer également :",
    alert:
      "Êtes vous sûr de vouloir réinitialiser ? Cette action est irréversible.",
    options: [
      "Les depenses, les catégories des dépenses et les sequences",
      "Les produits, les stocks et les catégories de produits ",
      "Les stocks",
      "Les clients",
    ],
    success: "Activité réinitialisée avec succès",
  },
  iosSubscriptionPayment:
    "Connectez-vous à partir d'un PC pour renouveler votre abonnement ou contactez votre conseiller client.",
  paydunya_url_error:
    "Impossible de générer un url de paiement. Réessayez plutard !",
  download_backup: "Télécharger une sauvegarde",
  download_backup_success: "Téléchargement de sauvegarde avec succès !",
  download_backup_error: "Echec de téléchargement de sauvegarde",
  restore_backup: "Restaurer à partir d'une sauvegarde",
  restauration_wait: "Patientez ...",
  ACTIVITY_NOT_FOUND: "L'activité à restaurer n'existe pas.",
  CANNOT_RESTORE_WITH_DIFFERENT_EXISTING_PROJECT:
    "Impossible de restaurer à partir d'une sauvegarde d'une autre activité.",
  restauration_success: "Restauration de sauvegarde avec succès !",
  restauration_error: "Echec de restauration de la sauvegarde !",
  FILE_CORRUPTED: "Votre fichier de sauvegarde est corrompu",
};
