export default {
  number: "Numero",
  types: [
    "Régul. de stock",
    "Inventaire",
    "Régul. de stock",
    "Entrée stock",
    "Sortie de stock",
  ],
  type: "Type",
  quantityAfter: "variation de stock",
  productCount: "Nbr. Produits",
  remove: {
    success: "Suppression du document reussie",
    failed: "Suppression du document echouée",
    warning:
      "Êtes vous sûr de vouloir supprimer ce document ? Toutes les modifications de stock apportées dans ce document seront annulées.",
  },
  deletedAt: "Document supprimé le",
};
