export default {
  hr_fistname: "Prénoms",
  hr_last_name: "Nom",
  cv: "CV",
  identity: "Pièce d'identité",
  function: "Fonction",
  sector: "Secteur",
  hr_choose_cv: "Choisir le CV",
  hr_choose_identity: "Choisir la pièce d'dentité",
  hr_departure_date: "Date de départ",
  hr_entry_date: "Date d'entrée",
  hr_parent_sector: "Secteur parent",
  hr_contacts: "Contacts"
};
