export default {
  welcome: `Bienvenue sur ${process.env.VUE_APP_NAME}`,
  registration: "Inscription",
  name: "Nom et prénoms",
  email: "Email",
  password: "Mot de passe",
  confirm: "Confimation du mot depasse",
  current: "Mot de passe actuel",
  promptLabel: "Entrer le mot de passe",
  forgot: "Mot de passe oublié ?",
  haveAccount: "Avez-vous déjà un compte ?",
  startTrial:
    "Inscrivez-vous et profitez d’une période d’utilisation gratuite de 30 jours.",
  signUp: "S'inscrire",
  connect: "Se connecter",
  failed: "Impossible de 's'inscrire maintenant. Réessayer ultérieurement",
  success: "Un code d'activation a été envoyé dans votre email!",
  exist: "Cette adresse email dispose un compte.Essayer de vous connecter.",
  filterByName: "Filtrer par nom",
  filterByEmail: "Filtrer par email",
  codePromo: "Code conseiller client(Facultatif)",
  validation: {
    password: "Les mots de passe ne correspondent pas",
    email: "Adresse email invalide",
    name: "Entrer votre nom et prénoms",
    codePromo: "Code conseiller client invalide",
    selectCountry: "Choisir votre pays",
  },
  invitationInvalid:
    "Invitation invalide! Contactez votre inviteur pour vous réinviter.",
  yourCode: "Votre code de validation est {code}",
  updateUserAccount: "Modifier les informations d'utilisateur",
};
