import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteRecordRaw,
} from "vue-router";
import { activeActivity } from "@/plugins/i18n";
import { CONSTANTS } from "@/graphql/utils/utils";
import { globalGuard } from "@/router/guard/global-guard";
import { AppSource } from "@/data/global";
import { secureLocalStorage } from "@/graphql/utils/encrypt-storage";
import { EAccessMenu } from "@/graphql/types";
import { canNavigateAnywhere, getAccess } from "@/utils/activity";

const pendingAccount = () => {
  const pendingAccount = sessionStorage.getItem(CONSTANTS.pendingAccount);
  return pendingAccount === "null" ? null : pendingAccount;
};
const forgotPassword = () => {
  const forgotPassword = sessionStorage.getItem(CONSTANTS.forgotPassword);
  return forgotPassword === "null" ? null : forgotPassword;
};

function checkRole(menu: EAccessMenu, next: NavigationGuardNext) {
  if (canNavigateAnywhere()) {
    next();
  } else if (menu == EAccessMenu.Activity) {
    next();
  } else {
    const canRead = getAccess(menu)?.read;
    // has connected to an activity
    if (activeActivity.value.id === 0) next({ name: "activity" });
    else if (canRead) next();
    else next({ name: "exception" });
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/Privacy.vue"),
  },
  {
    path: "/documentation",
    component: () => import("@/layouts/documentation/DocumentationLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("@/views/documentation/DocHome.vue"),
        name: "docHome",
      },
      {
        path: "account",
        component: () => import("@/views/documentation/DocAccount.vue"),
        name: "docAccount",
      },
      {
        path: "activity",
        component: () => import("@/views/documentation/DocActivity.vue"),
        name: "docActivity",
      },
      {
        path: "category",
        component: () => import("@/views/documentation/DocCategory.vue"),
        name: "docCategory",
      },
      {
        path: "product",
        component: () => import("@/views/documentation/DocProduct.vue"),
        name: "docProduct",
      },
      {
        path: "stock",
        component: () => import("@/views/documentation/DocStock.vue"),
        name: "docStock",
      },
      {
        path: "customer",
        component: () => import("@/views/documentation/DocCustomer.vue"),
        name: "docCustomer",
      },
      {
        path: "expense",
        component: () => import("@/views/documentation/DocExpense.vue"),
        name: "docExpense",
      },
      {
        path: "pos",
        component: () => import("@/views/documentation/DocPOS.vue"),
        name: "docPos",
      },
      {
        path: "rapport",
        component: () => import("@/views/documentation/DocRapport.vue"),
        name: "docRapport",
      },
      {
        path: "dashboard",
        component: () => import("@/views/documentation/DocDashboard.vue"),
        name: "docDashboard",
      },
    ],
  },
  {
    path: "/workspace",
    component: () => import("@/layouts/workspace/WorkspaceLayout.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        alias: "dashboard",
        component: () => import("@/views/workspace/Dashboard.vue"),
        name: "dashboard",
        beforeEnter: (_, __, next) => checkRole(EAccessMenu.Dashboard, next),
      },
      {
        path: "activity/:userAccountId?/:activityId?",
        component: () => import("@/views/workspace/Activity.vue"),
        name: "activity",
      },
      // {
      //   path: "activity/terminal",
      //   component: () => import("@/views/workspace/Terminal.vue"),
      //   name: "terminal",
      //   beforeEnter: (_, __, next) => checkRole([2, 3], next),
      // },
      {
        path: "category",
        component: () => import("@/views/workspace/Category.vue"),
        name: "category",
        beforeEnter: (_, __, next) => checkRole(EAccessMenu.Category, next),
      },
      {
        path: "product",
        component: () => import("@/views/workspace/Product.vue"),
        name: "product",
        beforeEnter: (_, __, next) => checkRole(EAccessMenu.Product, next),
      },
      {
        path: "customer",
        component: () => import("@/views/workspace/Customer.vue"),
        name: "customer",
        beforeEnter: (_, __, next) => checkRole(EAccessMenu.Customer, next),
      },
      {
        path: "stock",
        name: "stock",
        component: () => import("@/views/workspace/Stock.vue"),
        beforeEnter: (_, __, next) => checkRole(EAccessMenu.Stock, next),
      },
      {
        path: "expense",
        component: () => import("@/views/workspace/Expense.vue"),
        name: "expense",
        beforeEnter: (_, __, next) => checkRole(EAccessMenu.Expense, next),
      },
      {
        path: "account",
        component: () => import("@/views/workspace/Account.vue"),
        name: "account",
      },
      {
        path: "rapport",
        component: () => import("@/views/workspace/Rapport.vue"),
        name: "rapport",
        beforeEnter: (_, __, next) => checkRole(EAccessMenu.Rapport, next),
      },
      {
        path: "hr",
        component: () => import("@/views/workspace/HR.vue"),
        name: "hr",
      },
    ],
  },
  {
    path: "/workspace1/pos",
    component: () => import("@/views/workspace/POS.vue"),
    name: "pos",
    meta: { requiresAuth: true },
    beforeEnter: (_, __, next) => checkRole(EAccessMenu.Pos, next),
  },
  {
    path: AppSource.web.account,
    alias: Object.values(AppSource).map((link) => link.account),
    component: () => import("@/layouts/AccountLayout.vue"),
    children: [
      {
        path: "",
        alias: "sign-in",
        name: "signIn",
        component: () => import("@/views/SignIn.vue"),
        beforeEnter: (_, __, next) => {
          if (pendingAccount()) next({ name: "verifyAccount" });
          else next();
        },
      },
      {
        path: "sign-up/:id?",
        name: "signUp",
        component: () => import("@/views/SignUp.vue"),
      },
      {
        path: "forgot-password",
        name: "forgotPassword",
        component: () => import("@/views/ForgotPassword.vue"),
        beforeEnter: (_, __, next) => {
          if (forgotPassword()) next({ name: "resetPassword" });
          else next();
        },
      },
      {
        path: "reset-password",
        name: "resetPassword",
        component: () => import("@/views/ResetPassword.vue"),
        beforeEnter: (_, __, next) => {
          if (!forgotPassword()) next({ name: "forgotPassword" });
          else next();
        },
      },
      {
        path: "verify-account",
        name: "verifyAccount",
        component: () => import("@/views/VerifyAccount.vue"),
        beforeEnter: (_, __, next) => {
          if (!pendingAccount()) next({ name: "signUp" });
          else next();
        },
      },
    ],
  },
  {
    path: "/admin",
    component: () => import("@/layouts/workspace/AdminLayout.vue"),
    beforeEnter: (_, __, next) => {
      if (secureLocalStorage.getItem(CONSTANTS.userType) === 1) next();
      else next({ name: "exception" });
    },
    children: [
      {
        path: "",
        alias: "dashboard",
        component: () => import("@/views/admin/Dashboard.vue"),
        name: "admin_dashboard",
      },
      {
        path: "instigator",
        component: () => import("@/views/admin/Instigator.vue"),
        name: "instigator",
      },
      {
        path: "activity",
        component: () => import("@/views/admin/Activity.vue"),
        name: "admin_activity",
      },
      {
        path: "user",
        component: () => import("@/views/admin/AdminUsers.vue"),
        name: "user",
      },
      {
        path: "subscription",
        component: () => import("@/views/admin/Subscription.vue"),
        name: "subscription",
      },
      {
        path: "sub-payment",
        component: () => import("@/views/admin/SubscriptionPayment.vue"),
        name: "sub-payment",
      },
    ],
  },
  {
    path: "/exception",
    name: "exception",
    component: () => import("@/views/ErrorPage.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/exception",
  },
];

const route = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(_, __, ___) {
    // always scroll to top
    return { top: 0 };
  },
});
route.beforeEach(globalGuard);
export default route;
