
import { logout } from "@/graphql/session";
import { defineComponent } from "vue";
import { useConnexionLostDialog } from "@/graphql/utils/connexion-lost-dialog";

export default defineComponent({
  setup() {
    useConnexionLostDialog();
    return {};
  },
  created() {
    this.$primevue.config.locale = { ...this.$tm("prime") };
  },
  beforeUnmount() {
    logout(false);
  },
});
