export default {
  welcome: "Mot de passe oublié",
  title:
    "Entrez votre adresse email pour recevoir votre code de récupération du mot de passe.",
  placeholder: "Votre adresse email",
  have: "J'ai un code",
  back: "Retour",
  continue: "Continuer",
  sendCode: "Envoi du code pin",
  success: "Vériefier votre adresse email",
  failed: "",
  notFound: "Email introuvable",
  resentTimesOver: "Vous avez dépassé les 3 tentatives",
  yourCode: "Code de réinitialisation est",
};
