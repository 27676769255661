import { Ticket } from "../types";
import { totalInt, paymentSum } from "../utils/utils";

export const TICKET_FIELDS = `
    id
    sequenceId
    status
    number
    amount
    percentage
    createdAt
    parentId
`;

export enum TicketStatusEnum {
  PAYED = 0,
  CREDIT = 1,
  WAITING = 2,
  CREDIT_PAYED = 3,
  CANCELLED = 4,
  RETURN = 5,
  OFFER = 6,
}

export enum PaymentTypeEnum {
  ESPECE = 0,
  CHEQUE = 1,
  VIREMENT = 2,
  OFFERT = 3,
  CREDIT = 4,
  AUTRE = 5,
}

export const getDiscountValues = (ticket: Ticket, rows?: any[]) => {
  const payments = ticket?.payments || [];
  const received = paymentSum(payments);
  const total = totalInt(rows || ticket?.movements);

  const discount = ticket?.amount || 0;
  const percentage = ticket?.percentage || 0;
  let offerAmount = discount;
  let offerPercentage = percentage;

  if (!ticket?.id) {
    return {
      offerAmount,
      offerPercentage,
      discount,
      percentage,
      total,
      received,
      isOffered: false,
      sold: total - received - discount,
      realOffer: 0,
      totalNet: total - discount,
    };
  }

  const hasOffer = payments.filter((p) => p.method === PaymentTypeEnum.OFFERT);
  if (received === 0 && ticket?.status === 0) {
    return {
      offerAmount: total,
      offerPercentage: 100,
      discount: 0,
      percentage: 0,
      total,
      received,
      isOffered: true,
      sold: 0,
      realOffer: total,
      totalNet: 0,
    };
  }

  if (hasOffer[0]) {
    offerAmount = total - received;
    offerPercentage = (offerAmount * 100) / total;
  }

  return {
    sold: total - received - offerAmount,
    offerAmount,
    offerPercentage,
    total,
    received,
    isOffered: false,
    discount,
    percentage,
    realOffer: offerAmount - discount,
    totalNet: total - offerAmount,
  };
};
