export default {
  sales: "Ventes/Tickets",
  expenses: "Dépenses",
  cancelled: "Retours",
  ofSequences: "De {number} séquence(s)",
  comparison: "Bilan Recettes/Dépenses",
  stock: "Stock",
  ticketNumber: "N° ticket",
  client: "Client",
  amount: "Montant TTC Brut",
  balance: "Balance",
  productStock: "Stock en cours",
  movement: "Mouvements de stock",
  byCategories: "Filtrer par catégories",
  byProducts: "Filtrer par produits",
  byTickets: "Filtrer par tickets",
  filterOption: "Options de filtrage",
  categories: ["Principale", "Masquée"],
  openedAt: "Ouverte le",
  closedAt: "Fermée le",
  by: "Par",
  seq: "Séq.",
  total: "Montant Total",
  recapitulation: "Récapitulation",
  storableCount: "Nombre de produit stockable : {number}",
  movementCount: "Total de mouvements",
  cashingOut: "Décaissements",
  chooseSequence: "Séléction de séquence",
  b2Date: "Entre deux dates",
  byPeriod: "Par période",
  bySequence: "Par séquences",
  selectPeriod: "Séléctionner une période",
  period: "Période",
  from: "Du",
  to: "Au",
  tab0: "Rapport des ventes",
  tab1: "Rapport des dépenses",
  tab3: "Rapport de stock",
  tab4: "Rapport de Movements de stock",
  tab5: "Rapport des séquences",
  tab6: "Rapport des produits vendus",
  rapportOf: "Rapport du",
  seqCount: "Nombre de séquence : {count}",
  printing: "Impression",
  soldProducts: "Ventes/Produits",
  sequenceDetails: "Ticket de la séquence {number}",
  payed: "Montant payé",
  today: "Aujourd'hui",
  thisWeek: "Cette semaine",
  thisMonth: "Ce mois",
  three3LastMonth: "Les trois derniers mois",
  sixLastMonth: "Les six derniers mois",
  thisYear: "Cette année",
  allPeriods: "Toute période",
  netTotal: "Total Net",
  offered: "Offert",
  payedIncome: "Recettes payées",
  unpayedIncome: "Recettes crédits",
  totalIncome: "Recettes totales",
  saleWithIncome: "Ventes tickets avec PR",
  netSalePrice: "Prix de vente NET",
};
