import {
  Access,
  EAccessMenu,
  Responsible,
  ResponsibleModelFragment,
} from "@/graphql/types";
import { secureLocalStorage } from "@/graphql/utils/encrypt-storage";
import { CONSTANTS } from "@/graphql/utils/utils";
import { activeActivity, subscriptionExpired } from "@/plugins/i18n";

export const isAdmin = () =>
  secureLocalStorage.getItem(CONSTANTS.userType) == 1;

export const isOwner = () => activeActivity.value.isOwner;

export const canNavigateAnywhere = () => isAdmin() || isOwner();

export const getAccess = (menu: EAccessMenu, access?: Access[]) => {
  return (access || activeActivity.value.access).find(
    (acc) => acc.menu === menu
  );
};

export const getFirstReadAccess = (
  access?: Access[],
  canAccessAnyWhere = false
) => {
  if (canAccessAnyWhere) return "";
  if (!access) access = activeActivity.value.access;
  const hasDashboard = access.find(
    (acc) => acc.read && acc.menu === EAccessMenu.Dashboard
  );
  if (hasDashboard) return "";
  const acc = access.find(
    (acc) => acc.read && acc.menu !== EAccessMenu.Activity
  );
  return acc?.menu || EAccessMenu.Activity;
};

export const getPermissions = (menu: EAccessMenu) => {
  const acc = getAccess(menu);
  const noRestriction = canNavigateAnywhere();
  const notExpired = !subscriptionExpired();

  return {
    canUpdate: (acc?.update || noRestriction) && notExpired,
    canCreate: (acc?.create || noRestriction) && notExpired,
    canDelete: (acc?.delete || noRestriction) && notExpired,
  };
};
