import { createI18n } from "vue-i18n";
import messages from "../i18n/index";
import { ref } from "vue";
import {
  CONSTANTS,
  numberSeparators,
  remainSubscriptionDuration,
} from "@/graphql/utils/utils";
import { Access, SubscriptionPayment } from "@/graphql/types";
import moment from "moment";

export class ActiveActivity {
  id = 0;
  access: Access[] = [];
  isOwner = false;
  address = "";
  country = 0;
  createdAt = "";
  updatedAt = "";
  ownerId: number = null;
  currency = "";
  currencySymbol = "EUR";
  decimalNumber = 3;
  name = "";
  slogan = "";
  separator = 0;
  taxRate = 0;
  logo = "";
  payments: SubscriptionPayment[] = [];
  numberFormats? = {
    currency: {
      style: "currency",
      currency: "EUR",
      notation: "standard",
      currencyDisplay: "code",
    },
    decimal: {
      style: "decimal",
      maximumFractionDigits: 3,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  };
}

export const dateExpired = (exceededDay: number) => {
  return moment().add(exceededDay, "days").toDate();
};

export const activeActivity = ref<ActiveActivity>(
  JSON.parse(localStorage.getItem(CONSTANTS.activeActivity)) ||
    new ActiveActivity()
);

export const subscriptionExpired = (left = false) => {
  const remain = remainSubscriptionDuration(activeActivity.value);
  if (left) return remain;
  return remain <= 0;
};

const dateFormats: any = {
  long: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
};

export const i18n = createI18n({
  locale: "fr-FR",
  messages,
  fallbackLocale: "fr-FR",
  numberFormats: {
    [numberSeparators[activeActivity.value.separator]]:
      activeActivity.value.numberFormats,
  } as any,
  datetimeFormats: {
    "en-US": dateFormats,
    "fr-FR": dateFormats,
  },
});
