export default {
  bestSellers: "Meilleurs ventes",
  mostPopulars: "Les produits les plus populaires de votre collection",
  sales: "Ventes",
  employers: "Equipe",
  expiration: "Stocks & Expirations",
  recentActivity: "Votre activité commerciale dans le temps.",
  monthlyIncome: "Evolution des ventes et dépenses",
  monthlySalesVolume: "Etats des tickets",
};
