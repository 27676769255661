import signIn from "./sign-in";
import signUp from "./sign-up";
import verifyAccount from "./verify-account";
import forgotPassword from "./forgot-password";
import resetPassword from "./reset-password";
import workspaceLayout from "./workspace-layout";
import activity from "./activity";
import responsible from "./responsible";
import customer from "./customer";
import category from "./category";
import product from "./product";
import format from "./format";
import stock from "./stock";
import movement from "./movement";
import expense from "./expense";
import pos from "./pos";
import payment from "./payment";
import ticket from "./ticket";
import prime from "./prime";
import dashboard from "./dashboard";
import rapport from "./rapport";
import home from "./home";
import terminal from "./terminal";
import document from "./document";
import instigator from "./instigator";
import privacy from "./privacy";
import role from "./role";
import hr from "./hr";

export default {
  signIn,
  signUp,
  verifyAccount,
  forgotPassword,
  resetPassword,
  workspaceLayout,
  activity,
  responsible,
  category,
  product,
  format,
  stock,
  movement,
  expense,
  pos,
  payment,
  ticket,
  prime,
  dashboard,
  rapport,
  home,
  terminal,
  document,
  instigator,
  privacy,
  ...hr,
  emailCode: {
    failed: "Echec d'envoi",
    smtpError: "Erreur de service SMTP.",
    smtNoResponse: "Serveur SMTP ne répond pas !",
  },
  validation: {
    required: "Champs requis",
    code: "Il faut 6 chiffres",
    validPassword: [
      "Au moins une miniscule",
      "Au moins une majuscule",
      "Au moins un chiffre",
      "Minimum 8 caractères",
    ],
  },
  networkError: "Le serveur ne reponds pas. Vérifiez votre connexion internet",
  create: {
    title: "Création",
    success: "Création avec succès",
    failed: "Echec de création! Veuillez réessayer!",
  },
  noChange: "Vous n'avez effectué aucun changement.",
  search: "Chercher",
  submit: "Envoyer",
  remove: "Supprimer",
  restore: "Restaurer",
  crop: "Couper",
  clear: "Effacer les filtres",
  apply: "Appliquer",
  save: "Enregister",
  cancel: "Annuler",
  modify: "Modifier",
  finish: "Terminer",
  create0: "Créer",
  recover: "Récupérer",
  new: "Nouveau",
  refresh: "Rafraîchir",
  reset: "Réinitialiser",
  close: "Fermer",
  add: "Ajouter",
  detail: "Détailler",
  validate: "Valider",
  pay: "Payer",
  confirm: "Confirmer",
  refuse: "Refuser",
  login: "Se connecter",
  logout: "Se déconnecter",
  purchase: "Acheter",
  leave: "Quitter",
  back: "Retour",
  by: "Par",
  update: {
    title: "Mise à jour",
    success: "Mise à jour réussie",
    failed: "Echec de mise à jour! Veuillez réessayer!",
  },
  delete: {
    title: "Suppression",
    success: "Suppression réussie",
    failed: "Suppression echouée!",
    confirm: "Êtes-vous sur de vouloir supprimer?",
    permanently:
      "En continuant, les éléments supprimés seront irrécupérables ?",
    confirmation: "Confirmation",
  },
  previewImage: "Cliquez sur choisir pour déposer une image ici.",
  customer,
  yes: "Oui",
  no: "Non",
  all: "Tout",
  continue: "Continuer",
  edit: "Editer",
  checkAll: "Cocher tout",
  noResult: "Aucun résultat trouvé",
  noData: "Aucune donnée trouvée",
  choosePhoto: "Choisir une image",
  controls: "Contrôles",
  image: "Image",
  loading: "Chargement ...",
  createdAt: "Date de création",
  transactionId: "N°Transaction",
  in: "Entrée",
  out: "Sorties",
  date: "Date",
  removeAnyWay: "Supprimer quand même",
  languages: [
    {
      id: "0",
      label: "Français",
      value: "fr-FR",
    },
    {
      id: "1",
      label: "Anglais",
      value: "en-EN",
    },
  ],
  currentPageReportTemplate:
    "Montrant {first} à {last} de {totalRecords} entrées",
  id: "ID",
  year: "Année",
  you: "Vous",
  sendMail: {
    title: "Envoi de mail",
    success: "Votre message a été envoyé avec succès",
    error: "Service email indisponible. Réesayer plus tard!",
  },
  exp: "Exp.",
  remain: "Restant : ",
  lastDays: "Jours restants",
  dateA: "Date début",
  dateB: "Date fin",
  month: "Mois",
  invalidFileTypeMessage: "Fichiers invalides.",
  ca: "Chiffres d'affaires",
  number: " n°{number}",
  see: "Voir",
  exceptionTitle: "Page introuvable",
  exceptionDetail: "La ressource demandée n'est pas disponible.",
  goToDashboard: "Allez au tableau board",
  unknownError: "Une erreur inconnue est survenue! Reessayer plus tard",
  connexionError: "Erreur de connexion",
  connexionLost:
    "Votre connexion semble perdue. Merci de vérifier l'accès internet.",
  clear_: "Effacer",
  role,
  download_success: "Fichier téléchargé avec succès !",
  filterBy: "Filtrer par {field}",
};
