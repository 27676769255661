export default {
  code: "Code",
  filterByCode: "Filtrer par code",
  usersCount: "Nombre d'utilisateurs",
  namePlaceholder: "Entrer le nom du promoteur",
  new: "Nouveau promoteur",
  link: "Lien",
  notFound: "Code promo n'existe pas",
  codePromo: "Code promo",
};
