import { useConfirm } from "primevue/useconfirm";
import { useI18n } from "vue-i18n";
import { ref, watch } from "vue";

export const connexionLost = ref(false);
export const useConnexionLostDialog = () => {
  const dialog = useConfirm();
  const { t } = useI18n();

  watch(connexionLost, (lost) => {
    if (lost) {
      dialog.require({
        header: t("connexionError"),
        message: t("connexionLost"),
        group: "dialog",
        reject: () => window.location.reload(),
        accept: () => (connexionLost.value = false),
        icon: "pi pi-exclamation-triangle",
        rejectLabel: t("refresh"),
        acceptLabel: t("close"),
        acceptClass: "p-button-warning",
      });
    } else dialog.close();
  });
};
