export default {
  thanking: "Merci pour votre inscription",
  check: "Vérifiez votre adresse email",
  greeting: {
    title: "Salut ",
    body: "Nous vous avons envoyé votre code d’activation par email. Veuillez entrer votre code d’activation dans le champs ci-dessous pour terminer votre inscription.",
  },
  placeholder: "Code d’activation à 6 chiffres",
  button: "Vérifier",
  codeWrong: "Code invalide",
  title: "Verification du compte",
  success: "Votre compte est désormais activé. Merci !",
};
