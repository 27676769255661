import { i18n } from "@/plugins/i18n";
import { Category, Product } from "../types";

export const productParams = (product: Partial<Product>) => {
  const { name, reference } = product;
  switch (name) {
    case "discount":
      return {
        discount: true,
        priceSign: -1,
        name: i18n.global.t("payment.discount"),
        reference: "",
      };
    default:
      return {
        discount: false,
        priceSign: 1,
        name,
        reference,
      };
  }
};

export const categoryParams = (category: Partial<Category>) => {
  if (category.label?.includes("4UTYUIO"))
    return { name: i18n.global.t("payment.discount") };
  return {
    name: category.label,
  };
};

export const getPrice = (
  prod: Partial<{ quantity: number; price: number }>
) => {
  return Math.sign(prod.quantity) * prod.price;
};
