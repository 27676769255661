import moment from "moment";
import { useI18n } from "vue-i18n";
import { TicketRow } from "@/graphql/ticket/create.ticket";
import {
  Activity,
  Movement,
  PaymentReceipt,
  Product,
  Stock,
  Ticket,
} from "@/graphql/types";
import { activeActivity, i18n } from "@/plugins/i18n";
import { computed } from "vue";
import { PaymentTypeEnum, TicketStatusEnum } from "../ticket/ticket";

export const numberSeparators = ["fr-FR", "en-US", "de-DE", "en-IN", "jp-JP"];
export const DBDate = "YYYY-MM-DD";
export const DBDateLong = "YYYY-MM-DD HH:mm:ss GMT-0300";
export const cloneDeep = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const expired = (expiredAt: string | Date) => {
  if (!expiredAt) return 0;
  return moment(expiredAt).diff(Date.now(), "days") + 1;
};

export const formatDate = (date: any, format = DBDate) =>
  moment(date).format(format);

export const toDate = (date: string) =>
  moment(date, "DD-MM-YYYY").isValid()
    ? moment(date, "DD-MM-YYYY").toDate()
    : null;

export const useRoles = (callback: (val: number) => void) => {
  const { tm, t } = useI18n();
  const roles = (tm("activity.roles") as string[]).map((label, value) => ({
    label,
    command: () => callback(value),
    value,
  }));
  roles.unshift({
    label: t("activity.all"),
    command: () => callback(-1),
    value: -1,
  });
  return { roles };
};

export const makeTree = (items: readonly any[], parentId: number): any[] => {
  return items
    .filter((item) => item.parentId === parentId)
    .map((item) => ({
      key: item.id,
      label: item.label,
      type: "text",
      children: makeTree(items, item.id || item.key),
    }));
};

export const makeTreeTable = (
  items: readonly any[],
  parentId: number
): any[] => {
  return items
    .filter((item) => item.parentId === parentId)
    .map((item) => ({
      key: item.id,
      data: item,
      children: makeTreeTable(items, item.id || item.key),
    }));
};

export const filterQuery = (
  matchMode: string,
  field: string,
  value: any | any[]
) => {
  switch (matchMode) {
    case "startsWith":
      return `${field} LIKE "${value}%"`;
    case "contains":
      return `${field} LIKE "%${value}%"`;
    case "notContains":
      return `${field} NOT LIKE "${value}%"`;
    case "endsWith":
      return `${field} LIKE "%${value}"`;
    case "equals":
      return `${field} = ${value}`;
    case "notEquals":
      return `${field} <> ${value}`;
    case "in":
      return `${field} IN (${value.join(",")})`;
    case "lt":
      return `${field} < ${value}`;
    case "lte":
      return `${field} <= ${value}`;
    case "gt":
      return `${field} > ${value}`;
    case "gte":
      return `${field} >= ${value}`;
    case "between":
      return value[1]
        ? `DATE(${field}) BETWEEN "${formatDate(value[0])}" AND "${formatDate(
            value[1]
          )}"`
        : `Date(${field}) = DATE("${formatDate(value[0])}")`;
    case "dateIs":
      return `Date(${field}) = DATE("${formatDate(value)}")`;
    case "dateIsNot":
      return `DATE(${field}) <> DATE("${formatDate(value)}")`;
    case "dateBefore":
      return `DATE(${field}) < DATE("${formatDate(value)}")`;
    case "dateAfter":
      return `DATE(${field}) > DATE("${formatDate(value)}")`;
  }
};

export const subTotal = (row: TicketRow | Movement) => {
  return row.price * row.quantity;
};

export const totalInt = (rows: any[]) => {
  return rows.reduce((sum, cur) => sum + subTotal(cur), 0);
};

export const paymentSum = (payments: PaymentReceipt[]) => {
  if (!payments) return 0;
  return payments.reduce(
    (sum, cur) =>
      sum +
      (cur.method !== PaymentTypeEnum.OFFERT ? cur.received - cur.change : 0),
    0
  );
};
export const paymentRemain = (ticket: Ticket, preTot = 0) => {
  if (!ticket) return preTot;
  const tot = preTot ? preTot : totalInt(ticket.movements);
  return tot - ticket.amount - paymentSum(ticket.payments);
};
export const receivedSum = (payments: PaymentReceipt[]) => {
  if (!payments) return 0;
  return payments.reduce(
    (sum, cur) =>
      sum +
      (cur.method !== PaymentTypeEnum.OFFERT ? cur.received - cur.change : 0),
    0
  );
};

export const getTicketType = (ticket: Ticket): TicketStatusEnum => {
  if (ticket.status !== 0) return ticket.status;
  const payed = paymentSum(ticket?.payments);
  const isOffered = payed === 0 && ticket.status === 0;
  return isOffered ? 6 : ticket.status;
};

export const getImage = (img: string, folder = "products", actId = 0) => {
  let id = (actId || activeActivity.value.id) + "/";
  let defaultImage = `/locaa.png`;
  switch (folder) {
    case "products":
      defaultImage = "/product.jpg";
      break;
    case "categories":
      defaultImage = "/product.jpg";
      break;
    case "users":
      id = "";
      break;
    case "activity":
      folder = "";
      id = id.replace("/", "");
      break;
  }
  return img
    ? `${process.env.VUE_APP_API}api/file/${id}${folder}/${img}`
    : defaultImage;
};
export const activityLogo = computed(() => {
  const { logo, id } = activeActivity.value;
  return logo !== "" && logo !== JSON.stringify("")
    ? `${process.env.VUE_APP_API}api/file/${id}/${logo}`
    : "/locaa.png";
});
export const sumStock = (stocks: Stock[]) =>
  stocks.reduce((sm, cu) => sm + cu.quantity, 0);
export const makeTreeSelect = (
  items: readonly any[],
  parentId: number
): any[] => {
  return items
    .filter((item) => item.parentId === parentId)
    .map((item) => ({
      key: item.id,
      label: item.label,
      children: makeTreeSelect(items, item.id || item.key),
    }));
};

export const getSign = (value: number, motif: number) =>
  value != 0 ? (motif == 1 ? "+" : "-") : "";

export const remainSubscriptionDuration = (activity: Activity) => {
  const { payments, createdAt } = activity;
  const payment = payments[0];
  const expire = payment?.expiredAt || moment(createdAt).add(30, "days");
  return expired(expire);
};

export const CONSTANTS = {
  pendingAccount: "pendingAccount",
  activeActivity: "activeActivity",
  activeTerminal: "activeTerminal",
  forgotPassword: "forgotPassword",
  userType: "userType",
  stayConnected: "StayConnected",
  appSource: "appSource",
  token: "token",
  zoom: "zoom",
  zoomLevel: "zoomLevel",
  fullscreenAfterPrint: "fullscreenAfterPrint",
  productVisibleCols: "productVisibleCols",
  RAPPORT_INDEX: "RAPPORT_INDEX",
  rowsPerPageOptions: [10, 20, 40, 80, 100, 200, 500],
};

export const trim = (input: Record<string, any>) => {
  Object.keys(input).forEach((key) => {
    if (typeof input[key] === "string")
      input[key] = input[key].replace(/ +/g, " ");
  });
};

export const extractNumber = (quantity: string) => {
  quantity =
    quantity
      .replaceAll(numberSeparators[activeActivity.value.separator], "")
      .replaceAll(",", ".")
      .replace(/[^\d.-]/g, "") || "0";
  return Number(quantity);
};

export function totalStock(stocks: Stock[]) {
  return stocks.reduce((acc, cur) => acc + cur.quantity, 0);
}

export function formatNumber(value: number, discount = false) {
  value = value || 0;
  return discount
    ? Number(value.toFixed(2))
    : i18n.global.n(
        Number(value.toFixed(activeActivity.value.decimalNumber)),
        "decimal",
        numberSeparators[activeActivity.value.separator]
      );
}

export function getPrices(prod: Product, isTotal = false) {
  if (prod.type == 1) {
    return (
      formatNumber(
        prod?.notStorable?.inclTax || prod?.notStorable?.exclTax || 0
      ) + activeActivity.value.currencySymbol
    );
  }
  if (isTotal) {
    return `${formatNumber(
      prod.stocks.reduce((t, stock) => t + stock.inclTax * stock.quantity, 0)
    )} ${activeActivity.value.currencySymbol}`;
  }
  return prod.stocks
    .map((s) => formatNumber(s.inclTax) + activeActivity.value.currencySymbol)
    .join(" / ");
}

export function getPurchasePrices(prod: Product, isTotal = false) {
  if (prod.type == 1) {
    return (
      formatNumber(prod?.notStorable?.purchasePrice) +
      activeActivity.value.currencySymbol
    );
  }

  if (isTotal) {
    return `${formatNumber(
      prod.stocks.reduce(
        (t, stock) => t + stock.purchasePrice * stock.quantity,
        0
      )
    )} ${activeActivity.value.currencySymbol}`;
  }

  return prod.stocks
    .map(
      (s) => formatNumber(s.purchasePrice) + activeActivity.value.currencySymbol
    )
    .join(" / ");
}
