export default {
  return: "Retour",
  ticket: "Tickets",
  credit: "Crédits",
  waiting: "Tickets en attente",
  expense: "Dépenses",
  sale: "Ventes",
  closure: "Fermeture",
  opening: "Ouverture",
  print: "Imprimer",
  cartAdd: "Ajouter au panier",
  stocks: "stocks",
  selectCategory: "Séléctionner les catégories",
  open: "Ouvrir",
  product: "Produits",
  amount: "Somme",
  close: "Clôturer",
  sequence: "Séquence",
  terminal: "Terminal",
  openedAt: "Date d'ouverture",
  opened: "Séquence ouverte avec succès",
  closed: "Séquence clôturée avec succès !",
  failed: "Ouverture de séquence echouée",
  failedClose: "Fermeture de la séquence echouée",
  cancelling: "Annulations",
  waitingClose:
    "Impossible de fermer une séquence avec des tickets en attente.",
  closure0: {
    title: "Fermeture de la séquence",
    button: "Fermer la séquence",
    warning:
      "En continuant, la séquence sera fermée. ? Une séquence fermée ne peut plus être réouverte.",
  },
  invalidQuantity: "Saisir une quantité strictement positive",
  openCasing: "La caisse a été clôturée, veuillez ouvrir une nouvelle séquence",
  item: "articles",
  quantity: "qté",
  inTax: "Total TTC",
  upTax: "PU TTC",
  tax: "Taxe",
  payment: "Paiements",
  cancelTicket: "Annuler un ticket",
  resultCount: "Résultats",
  creditsPayments: "Paiements des crédits",
};
